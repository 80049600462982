import React, { useState, useEffect } from "react";
import { styles } from "../../../../styles/Dashboard/data";
import { styles as dataTableStyles } from "../../../../styles/dataTable";
import { withMultipleStyles } from "../../../../styles/withMultipleStyles";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./actionButtons";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

// redux
import {
  list,
  updateList,
  resetSelected,
} from "../../../../store/MaterialTags/materialTags";

const AllTags = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [selectIdToDeleteTag, setSelectIdToDeleteTag] = useState(false);

  const {
    data,
    loading,
    searchValue,
    selected: currentRecord,
  } = useSelector(({ materialTags }) => materialTags.slice);

  const { totalElements } = data;

  const [showAddModal, setShowAddModal] = useState(false);

  const throwMsg = (ex) => {
    dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
  };
  const displayMsg = (res) => {
    if (res && typeof res === "string") {
      dispatch(showMessage({ message: res }));
    }
  };

  const handleSelectedId = (id) => {
    setSelectIdToDeleteTag(id);
  };

  const clearId = () => {
    setSelectIdToDeleteTag(null);
  };

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    dispatch(list({ pageNo, pageSize }));
  }, [pageNo, pageSize]);

  const headers = [
    { name: "ID", options: { sort: false } },
    { name: "Datasource Id", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return <ActionButtons rowData={value} onDelete={handleSelectedId} />;
        },
      },
    },
  ];

  const getParsedData = () => {
    return data?.content?.map((m) => [
      m.id,
      m?.dataSourceId,
      {
        id: m?.id,
        dataSourceId: m?.dataSourceId,
      },
    ]);
  };

  const { classes } = props;

  const options = {
    sort: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    search: false,
    onColumnSortChange: () => toggleSorting(),
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div className={classes.pageWrapper}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Material Tags"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(AllTags);
