import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordField = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      innerRef={ref}
      {...props}
      id="password-input"
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" style={{ width: "30px" }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
              size="large">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

PasswordField.displayName = "PasswordField";

export default PasswordField;
