import download, { downloadPDF } from "./download";

export default function downloadAllImages(images, key) {
  images.map((image, index) => {
    setTimeout(() => {
      download(image[key], +new Date().valueOf());
    }, index * 500);
  });
}

export async function downloadAllTypesDocuments(documents, key) {
  const DOC_KEY = "documentTypeImage";
  const downloadPromises = [];

  documents.forEach((doc, index) => {
    const item = doc[key];

    let promise;

    switch (typeof item) {
      case "string":
        promise = new Promise((resolve) => {
          setTimeout(() => {
            downloadPDF(item, +new Date().valueOf()).then(resolve);
          }, index * 500);
        });
        downloadPromises.push(promise);
        break;

      case "object":
        if (item instanceof Array) {
          promise = downloadAllTypesDocuments(item, DOC_KEY);
          downloadPromises.push(promise);
        }
        break;
    }
  });

  // Wait for all the downloads to complete
  return Promise.all(downloadPromises);
}
