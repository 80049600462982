import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { styles } from "../../../../styles/actionButtons";
import withStyles from "@mui/styles/withStyles";
import {
  updateSelectedData_Source,
  deleteSource,
} from "../../../../store/Attributes/attributeSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../../common/AlertDialog/AlertDialog";

const ITEM_HEIGHT = 48;

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState();
  const open = Boolean(anchorEl);
  const { classes, data } = props;
  const dispatch = useDispatch();

  const alertConfirmation = useSelector(
    ({ alert }) => alert.alertSlice.alertConfirm,
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editHandler = async () => {
    await dispatch(updateSelectedData_Source(data));
    handleClose();
  };

  const showAlertDialogDelete = () => {
    setAlert(true);
    setSelectValue(data);
  };

  const deleteHandler = async () => {
    if (alertConfirmation == true) {
      dispatch(deleteSource(data));
      setAlert(false);
    }
  };

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        }}>
        <MenuItem onClick={editHandler}>Edit</MenuItem>
        <MenuItem onClick={showAlertDialogDelete}>Delete</MenuItem>
      </Menu>

      {alert === true ? (
        <AlertDialog
          title="Delete Attribute Source"
          message="Are you sure want to delete this Attribute Source?"
          onLoad={deleteHandler()}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default withStyles(styles)(ActionButtons);
