import React from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { styles } from "../../../../styles/Dashboard/data";
import { Controller, useForm } from "react-hook-form";

const schema = yup.object().shape({
  apiKey: yup
    .string()
    .min(
      20,
      "API key should have at least 20 characters and maximum 300 characters",
    )
    .max(
      300,
      "API key should have at least 20 characters and maximum 300 characters",
    ),
});

const AddAPIKey = (props) => {
  const { classes, onAddAPIKey = () => {}, loading } = props;
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: { apiKey: "" },
    resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  return (
    <div className={classes.pageWrapper}>
      <form onSubmit={handleSubmit(onAddAPIKey)}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <Controller
                name="apiKey"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="add-api-key"
                    error={!!errors.apiKey}
                    helperText={errors?.apiKey?.message}
                    variant="outlined"
                    placeholder="API Key*"
                    label="API Key"
                    required
                  />
                )}
              />
            </div>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || loading}
            style={{ marginTop: "10px" }}
            className={classes.submitButton}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddAPIKey);
