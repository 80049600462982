import axios from "axios";
import { BASE_URL_EPD, BASE_URL_V2 } from "../../config/config";

class AttributeService {
  getAllElements = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "/elements/allPaginatedElements?pageNo=" +
            0 +
            "&pageSize=" +
            100,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.content);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getAllLevel1 = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "broaderCategory/allPaginatedBroaderCategory?pageNo=" +
            0 +
            "&pageSize=" +
            100,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.content);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getAllLevel2 = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "subCategory/allPaginatedSubCategory?pageNo=" +
            0 +
            "&pageSize=" +
            100,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.content);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  addNewElement = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "elements/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  submitAddLevel1 = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "broaderCategory/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  submitAddLevel2 = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "subCategory/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  submitAddRelationship = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "categoryHierarchy/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateDataElement = (value, id) => {
    const data = {
      id: id,
      elementsName: value.elementsName,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_EPD + "elements/update" + `?id=${id}`, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateLevel1 = (value, id) => {
    const data = {
      id: id,
      broaderCategoryName: value.broaderCategoryName,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_EPD + "broaderCategory/update" + `?id=${id}`, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateLevel2 = (value, id) => {
    const data = {
      id: id,
      subCategoryName: value.subCategoryName,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_EPD + "subCategory/update" + `?id=${id}`, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateSelectedData = (data) => {
    return new Promise((resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  };

  deleteElement = (data) => {
    const reqData = {
      params: {
        id: data[0],
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "elements/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteLevel1 = (data) => {
    const reqData = {
      params: {
        id: data[0],
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "broaderCategory/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteLevel2 = (data) => {
    const reqData = {
      params: {
        id: data[0],
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "subCategory/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}
const instance = new AttributeService();

export default instance;
