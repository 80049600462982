import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_HOST_ADMIN_USERS } from "../config/config";

const useAccountTypes = () => {
  const [data, setData] = useState({});
  const getAttr = async () => {
    try {
      const res = await axios.get(
        BASE_HOST_ADMIN_USERS + "/core-account-types/getAccountTypes",
      );

      if (res.status === 200) {
        const response = res.data.data;
        const result = response.map((i) => {
          return {
            id: i?.id,
            name: i?.name,
          };
        });
        setData(result);
      } else {
        setData({});
      }
    } catch (ex) {
      setData({});
    }
  };
  useEffect(() => {
    getAttr();
  }, []);

  return data;
};

export default useAccountTypes;
