import React, { createContext, useState } from "react";
import { Theme, DarkTheme } from "./../resources/theme";

const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const setLighTheme = () => {
    setSelectedTheme(Theme);
  };

  const setDarkTheme = () => {
    setSelectedTheme(DarkTheme);
  };

  const [selectedTheme, setSelectedTheme] = useState(Theme);

  const providerValue = {
    selectedTheme,
    actions: {
      setLighTheme,
      setDarkTheme,
    },
  };

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
