import axios from "axios";
import { BASE_URL_V2 } from "../../config/config";
/* eslint-disable camelcase */

const ENDPOINT = "lca";

class LCAService {
  import = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + "lca/import", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getImportProcessStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "lca/data-import/status")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  list = (data) => {
    const { pageNo = 1, pageSize = 10, searchValue = "" } = data;
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "lca/all?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            "&identifier=" +
            searchValue,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  oekobaudatlist = (data) => {
    const { pageNo = 0, pageSize = 10, searchValue = "" } = data || {};
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "oekobaudat/get-oekobaudat-data?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            "&identifier=" +
            searchValue,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  upload = (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + "lca/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  download = (data) => {
    const { id, type } = data;
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            `lca/download-epd-from-ecoPortal?downloadType=${type}&identifier=${id}&` +
            `${type === "JSON" && "&fromLocalStorage=true"}`,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  create = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + "lca/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  update = (data, id) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_V2("epd") + "lca/update?id=" + id, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  deleteByName = (name = "") => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_V2("epd") + "lca/delete?objectName=" + name)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}

const instance = new LCAService();

export default instance;
