import React from "react";
import { Grid, Typography } from "@mui/material";
import { renderValue } from "../../../../../common/Helper/renderValue";

const AttributeDetails = ({ data, title }) => {
  const modellingAllocation =
    data?.modellingAndValidation?.LCIMethodAndAllocation
      ?.referenceToLCAMethodDetails;

  const modellingDetails = data?.modellingAndValidation?.LCIMethodAndAllocation;

  const medellingReviewer =
    data?.modellingAndValidation?.validation?.review[0]
      ?.referenceToNameOfReviewerAndInstitution;

  const modellingDataSource =
    data?.modellingAndValidation?.dataSourcesTreatmentAndRepresentativeness
      ?.referenceToDataSource;

  const geographyProd =
    data?.processInformation?.geography?.locationOfOperationSupplyOrProduction
      ?.descriptionOfRestrictions;

  const geographyDesc =
    data?.processInformation?.geography?.locationOfOperationSupplyOrProduction;

  const technology =
    data?.processInformation?.technology
      ?.technologyDescriptionAndIncludedProcesses;

  return (
    <Grid container spacing={2}>
      {Boolean(title) && (
        <Grid item>
          <Typography variant="subTitle1" component="h2">
            {title}
          </Typography>
        </Grid>
      )}
      {/* <strong>{"ID"}:</strong> {data.id}
      <br />
      <strong>{"Platform Name"}:</strong> {data.platformName}
      <br />
      <strong>{"Version"}:</strong> {data.version}
      <br /> */}
      {data &&
        Object.keys(data)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={data[m] && data[m].length > 100 ? 12 : 6}
                    key={m}>
                    <strong>{m}:</strong> {renderValue(data[m])}
                  </Grid>
                  <br />
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>LCIAResults</u>
        </h3>
      </Grid>
      {data?.LCIAResults?.LCIAResult &&
        Object.keys(data?.LCIAResults?.LCIAResult[0])
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.LCIAResults?.LCIAResult[0][m] &&
                      data?.LCIAResults?.LCIAResult[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(data?.LCIAResults?.LCIAResult[0][m])}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
      <Grid item xs={12} lg={12}>
        <h3>
          <u>Administrative Information</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Data Entry By</u>
        </h5>
      </Grid>
      {data?.administrativeInformation?.dataEntryBy &&
        Object.keys(
          data?.administrativeInformation?.dataEntryBy
            ?.referenceToDataSetFormat[0],
        )
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m] &&
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m],
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Publication And Ownership</u>
        </h5>
      </Grid>
      {data?.administrativeInformation?.publicationAndOwnership &&
        Object.keys(data?.administrativeInformation?.publicationAndOwnership)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ] &&
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ],
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Exchanges</u>
        </h3>
      </Grid>
      {data?.exchanges?.exchange &&
        Object.keys(data?.exchanges?.exchange[0])
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.exchanges?.exchange[0][m] &&
                      data?.exchanges?.exchange[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(data?.exchanges?.exchange[0][m])}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Modelling And Validation</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={12}>
        <h5>
          <u>LCI Method And Allocation</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.LCIMethodAndAllocation &&
        Object.keys(modellingAllocation !== null ? modellingAllocation[0] : "")
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid item xs={12} lg={6} key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      modellingAllocation !== null
                        ? modellingAllocation[0][m]
                        : "",
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Compliance Declarations</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.complianceDeclarations &&
        Object.keys(
          data?.modellingAndValidation?.complianceDeclarations?.compliance[0],
        )
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.modellingAndValidation?.complianceDeclarations
                        ?.compliance[0][m] &&
                      data?.modellingAndValidation?.complianceDeclarations
                        ?.compliance[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.modellingAndValidation?.complianceDeclarations
                        ?.compliance[0][m],
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Data Sources Treatment And Representativeness</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation
        ?.dataSourcesTreatmentAndRepresentativeness &&
        Object.keys(modellingDataSource !== null ? modellingDataSource[0] : "")
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid item xs={12} lg={6} key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      modellingDataSource !== null
                        ? modellingDataSource[0][m]
                        : "",
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h5>
          <u>Validation</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.validation &&
        Object.keys(medellingReviewer !== null ? medellingReviewer[0] : "")
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid item xs={12} lg={6} key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      medellingReviewer !== null ? medellingReviewer[0] : "",
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Process Information</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Data Set Information</u>
        </h5>
      </Grid>
      {data?.processInformation?.dataSetInformation &&
        Object.keys(data?.processInformation?.dataSetInformation)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.processInformation?.dataSetInformation[m] &&
                      data?.processInformation?.dataSetInformation[m].length >
                        100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.processInformation?.dataSetInformation[m],
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Geography</u>
        </h5>
      </Grid>
      {/* {data?.processInformation?.geography &&
        Object.keys(geographyProd !== null ? geographyProd[0] : "")
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      geographyProd[0] && geographyProd[0].length > 100 ? 12 : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      geographyProd !== null ? geographyProd[0] : "",
                    )}
                  </Grid>
                </>
              )
            } else {
              return null
            }
          })} */}
      {data?.processInformation?.geography ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>location</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.geography
                    ?.locationOfOperationSupplyOrProduction?.location
                }
              </span>
            </p>
            {/* <p>
              <b>lang</b>
              <span style={{ marginLeft: "5px" }}>en</span>
            </p> */}
            {data?.processInformation?.geography
              ?.locationOfOperationSupplyOrProduction?.descriptionOfRestrictions
              ?.length &&
            data?.processInformation?.geography
              ?.locationOfOperationSupplyOrProduction
              ?.descriptionOfRestrictions[0] ? (
              <>
                <p>
                  <b>value</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.geography
                        ?.locationOfOperationSupplyOrProduction
                        ?.descriptionOfRestrictions[0].value
                    }
                  </span>
                </p>
                <p>
                  <b>lang</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.geography
                        ?.locationOfOperationSupplyOrProduction
                        ?.descriptionOfRestrictions[0].lang
                    }
                  </span>
                </p>
              </>
            ) : (
              <p>
                <b>lang</b>
                <span style={{ marginLeft: "5px" }}>en</span>
              </p>
            )}
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Quantitative Reference</u>
        </h5>
      </Grid>
      {data?.processInformation?.quantitativeReference &&
        Object.keys(data?.processInformation?.quantitativeReference)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.processInformation?.quantitativeReference[m] &&
                      data?.processInformation?.quantitativeReference[m]
                        .length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.processInformation?.quantitativeReference[m],
                    )}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h5>
          <u>Technology</u>
        </h5>
      </Grid>
      {data?.processInformation?.technology &&
        technology !== null &&
        Object.keys(technology[0] ? technology[0] : "")
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={technology[0] && technology[0].length > 100 ? 12 : 6}
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(technology[0] ? technology[0][m] : "")}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}

      <Grid item xs={12} lg={12}>
        <h5>
          <u>Time</u>
        </h5>
      </Grid>
      {data?.processInformation?.time &&
        Object.keys(data?.processInformation?.time)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.processInformation?.time[m] &&
                      data?.processInformation?.time[m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(data?.processInformation?.time[m])}
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
    </Grid>
  );
};

export default AttributeDetails;
