import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";

const AlertDialogNew = ({ open, closeDialog, buttons, title, message }) => {
  const { actionCall: confirmCall, buttonText: confirmText } = buttons.confirm;
  const { actionCall: cancelCall, buttonText: cancelText } = buttons.cancel;

  const cancelHandler = () => {
    cancelCall();
    closeDialog();
  };

  const confirmHandler = () => {
    confirmCall();
    closeDialog();
  };

  return open ? (
    <div>
      <Dialog open={true} maxWidth="sm" fullWidth onClose={cancelHandler}>
        <DialogTitle>{title ? title : "Confirmation"}</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton size="large">{/* <Close /> */}</IconButton>
        </Box>
        <DialogContent>
          <Typography>{message ? message : ""}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={cancelHandler}>
            {cancelText}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={confirmHandler}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default AlertDialogNew;
