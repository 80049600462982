import { createSlice } from "@reduxjs/toolkit";
import { OriginalEPDService } from "../../services/originalEPDService";

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return OriginalEPDService.list(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};
export const getById = (id) => async (dispatch) => {
  return OriginalEPDService.getById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const getOekobaudatById = (id) => async (dispatch) => {
  return OriginalEPDService.getOekobaudatById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const deleteById = (id) => async (dispatch) => {
  return OriginalEPDService.deleteById(id)
    .then((res) => {
      if (Boolean(res?.deletedCount)) {
        dispatch(removeRecord(id));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const update = (data) => async (dispatch) => {
  return OriginalEPDService.update(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const changeBlockedStatus = (data) => async (dispatch) => {
  return OriginalEPDService.changeBlockedStatus(data)
    .then((res) => {
      if (Boolean(res?.modifiedCount)) {
        dispatch(modifyRecord(data));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const exportData = (params) => async (dispatch) => {
  dispatch(setExportLoading(true));
  return OriginalEPDService.export(params)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportLoading(false));
    });
};
export const getExportProcessStatus = () => async (dispatch) => {
  return OriginalEPDService.getExportProcessStatus()
    .then((res) => {
      dispatch(setExportProcessStatus(res));
      return res;
    })
    .catch((errors) => {
      dispatch(setExportProcessStatus(null));
      return errors;
    });
};
export const startExportProcess = () => async (dispatch) => {
  dispatch(setExportProcessLoading(true));
  return OriginalEPDService.startExportProcess()
    .then(async (res) => {
      await dispatch(getExportProcessStatus());
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportProcessLoading(false));
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  exportLoading: false,
  exportProcessLoading: false,
  exportProcessStatus: null,
};

const originalEPDSlice = createSlice({
  name: "originalEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    modifyRecord: (state, action) => {
      const { id, blocked } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content[index].blocked = blocked;
        state.originalData.content[index].blocked = blocked;
      }
    },
    removeRecord: (state, action) => {
      const id = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content.splice(index, 1);
        state.originalData.content.splice(index, 1);
      }
    },
    setExportProcessStatus: (state, action) => {
      state.exportProcessStatus = action.payload;
    },
    setExportLoading: (state, action) => {
      state.exportLoading = action.payload;
    },
    setExportProcessLoading: (state, action) => {
      state.exportProcessLoading = action.payload;
    },
    searchData: (state, action) => {
      let { search } = action.payload;
      if (!search) {
        state.data = state.originalData;
        return;
      }
      search = search.toLowerCase();
      const searchData = state.originalData.content.filter((f) => {
        return (
          f["Name (no)"] && f["Name (no)"].toLowerCase().indexOf(search) > -1
        );
      });
      state.data = {
        content: searchData,
        totalPages: 1,
        totalElements: searchData.length,
      };
    },
  },
});

export const {
  setLoading,
  setData,
  modifyRecord,
  setExportLoading,
  setExportProcessStatus,
  setExportProcessLoading,
  searchData,
  removeRecord,
} = originalEPDSlice.actions;

export default originalEPDSlice.reducer;
