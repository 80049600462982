import React, { Component } from "react";
import { styles } from "../../styles/drawerSidebar";
import withStyles from "@mui/styles/withStyles";
import { Drawer } from "@mui/material";

class DrawerSideBar extends Component {
  render() {
    const { classes, open } = this.props;

    return (
      <>
        <div className={`${classes.drawerSidebarList}`}>
          <Drawer
            open={open}
            variant="persistent"
            elevation={16}
            anchor="right"
            className={classes.mblRightDrawer}>
            {this.props.children}
          </Drawer>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(DrawerSideBar);
