import axios from "axios";
import { BASE_HOST_ADMIN_USERS } from "../../config/config";
import axiosRequest from "../axiosReq";
/* eslint-disable camelcase */

class AdminUserService {
  getAllAdminUsersData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_HOST_ADMIN_USERS + "/users/getAllAdminUsers", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  createAdminUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_HOST_ADMIN_USERS + "/users/addAdminUser", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  updateAdminUser = (data, id) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_HOST_ADMIN_USERS + "/users/updateUser/" + id, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  deleteAdminUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_HOST_ADMIN_USERS + "/users/deleteAdminUser/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  // METHODS TO ADD/DELETE API KEY
  addAPIKey = ({ userId, apiKey }) => {
    const req = axios.put(
      BASE_HOST_ADMIN_USERS +
        "/users/assignApiKey?userId=" +
        userId +
        "&apiKey=" +
        apiKey,
    );

    return axiosRequest(req);
  };
  deleteAPIKey = ({ userId }) => {
    const req = axios.delete(
      BASE_HOST_ADMIN_USERS + "/users/deleteApiKey/" + userId,
    );

    return axiosRequest(req);
  };
  // : METHODS TO ADD/DELETE API KEY
}

const instance = new AdminUserService();

export default instance;
