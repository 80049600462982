import React, { useState, useEffect } from "react";
import { styles } from "../../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "../../../../common/DataTable/dataTable_new";
import DrawerSideBar from "../../../../common/Drawer/drawer";
import CreateForm from "./createForm";
import axios from "axios";
import { BASE_URL_CONTENT } from "../../../../config/config";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const Glossary = (props) => {
  const dispatch = useDispatch();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [buzzWrods, setBuzzWord] = useState([]);
  const [buzzData, setBuzzData] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [pageId, setPageId] = useState("");
  const { classes } = props;

  const addNewBuzzWord = (data) => {
    const newBuzz = {
      definition: data.definition,
      word: data.word,
    };

    const updatedBuzzWords = [...buzzWrods, newBuzz].map((item) => {
      return [item.id, item.definition, item.word];
    });
    setBuzzData(() => {
      return [...buzzData, newBuzz];
    });

    setBuzzWord([...updatedBuzzWords]);
    saveData([...buzzData, newBuzz]);
  };

  const saveData = (updatedList) => {
    const data = {
      buzzwords: [...updatedList],
      languageCode: "en",
    };
    if (!shouldUpdate) {
      axios
        .post(BASE_URL_CONTENT + "glossary-page/create", data)
        .then((res) => {
          dispatch(showMessage({ message: "List Updated Successfuly" }));
        })
        .catch((err) => {});
      return;
    }
    axios
      .put(BASE_URL_CONTENT + "glossary-page/update", {
        ...data,
        id: pageId,
      })
      .then((res) => {
        dispatch(showMessage({ message: "List Updated Successfuly" }));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    axios
      .get(BASE_URL_CONTENT + "glossary-page?langaugeCode=en")
      .then((res) => {
        if (res.data.id) {
          setPageId(res.data.id);
          setShouldUpdate(true);
        }
        const updatedBuzzWords = [...res.data.buzzwords].map((item) => {
          return [item.id, item.definition, item.word];
        });

        setBuzzWord([...updatedBuzzWords]);
        dispatch(showMessage({ message: "Data Fetched Successfuly" }));
      })
      .catch((err) => {});
  }, [dispatch]);

  const headings = ["id", "Word", "Definition"];

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">Glossary Page</h1>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={() => setIsCreateMode(!isCreateMode)}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            Add new Buzz Word
          </Button>
        </div>
      </div>
      <DataTable headings={headings} rowsData={buzzWrods} />
      {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          <CreateForm
            setIsCreateMode={setIsCreateMode}
            addNewBuzzWord={addNewBuzzWord}
          />
        </DrawerSideBar>
      )}
    </div>
  );
};

export default withStyles(styles)(Glossary);
