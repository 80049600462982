import React from "react";
import { Grid, Typography } from "@mui/material";
import { renderValue } from "../../../common/Helper/renderValue";

const AttributeDetails = ({ data, title }) => {
  return (
    <Grid container spacing={2}>
      {Boolean(title) && (
        <Grid item>
          <Typography variant="subTitle1" component="h2">
            {title}
          </Typography>
        </Grid>
      )}

      {data &&
        Object.keys(data)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m !== "_id" && m !== "bannerImage" && m !== "logoImage") {
              return (
                <Grid
                  item
                  xs={12}
                  lg={data[m] && data[m].length > 100 ? 12 : 6}
                  key={m}>
                  <strong>{m}:</strong> {renderValue(data[m])}
                </Grid>
              );
            } else {
              return null;
            }
          })}
      {data &&
        Object.keys(data)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((m) => {
            if (m === "bannerImage" || m == "logoImage") {
              return (
                <Grid item xs={12} lg={6} key={m}>
                  <strong>{m}:</strong> <br />
                  <img src={data[m]} height={300} width={400} />
                </Grid>
              );
            } else {
              return null;
            }
          })}
    </Grid>
  );
};

export default AttributeDetails;
