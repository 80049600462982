import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, CircularProgress } from "@mui/material";
import { styles } from "../../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { deleteById, list } from "../../../../store/MaterialTags/materialTags";

import { useDispatch } from "react-redux";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import AlertDialog from "../../../../common/AlertDialog/AlertDialog";
import { setConfirmation } from "../../../../store/AlertDialog/alertDialogSlice";

const ITEM_HEIGHT = 48;
const STATUS = {
  WARNING: "Warning",
  SUCCESS: "Success",
};

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const { classes, rowData, onEdit = () => {} } = props;
  const { id, blocked, status } = rowData;
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    onEdit(id);
    handleClose();
  };

  const handleDelete = () => {
    setAlert(true);
    handleClose();
  };
  const handleCancel = () => {
    dispatch(setConfirmation(false));
    setAlert(false);
  };

  const handleConfirm = async () => {
    const dataSourceId = rowData?.dataSourceId;
    try {
      setLoading(true);
      setAlert(false);
      const res = await dispatch(deleteById(dataSourceId));
      if (Boolean(res)) {
        dispatch(showMessage({ message: "Record deleted" }));
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.message : ex, // Corrected "ex.message"
          variant: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress size={20} color="primary" />;
  }

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 7.0,
          width: "33ch",
        }}>
        {/* <MenuItem onClick={handleEdit}>Edit Tag</MenuItem> */}
        <MenuItem onClick={handleDelete}>Delete Tag</MenuItem>
      </Menu>

      {alert ? (
        <AlertDialog
          title="Delete"
          message="Are you sure want to delete this Tag?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default withStyles(styles)(ActionButtons);
