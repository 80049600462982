export const styles = (theme) => ({
  sidebarWrapper: {
    background: theme.palette.menu.background,
    padding: 22,
    width: "auto",
    borderRight: "1px solid " + theme.palette.menu.border,
  },
  sidebarLinks: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: theme.palette.menu.icons,
    padding: 11,
    textDecoration: "none",
    cursor: "pointer",
    "& svg": {
      fontSize: 17,
      color: theme.palette.menu.icons,
    },
    "&:hover": {
      background: theme.palette.menu.active.bg,
      color: theme.palette.menu.active.color,
      "& svg": {
        color: theme.palette.menu.active.color,
      },
    },
  },
  arrow: {
    color: theme.palette.menu.arrow,
    fontSize: 17,
    display: "block",
    margin: "auto",
    cursor: "pointer",
  },
  arrowActive: {
    color: theme.palette.menu.active.color,
    fontSize: 17,
    display: "block",
    marginLeft: "auto",
    cursor: "pointer",
  },
  menuItemLinkStyle: {
    color: theme.palette.menu.active.color,
    textDecoration: "none",
    width: "100%",
  },
  submenu_one: {
    paddingLeft: 21,
  },
  submenu_two: {
    paddingLeft: 30,
  },
});
