import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import React, { useState } from "react";
import ActionButtonsMultipleUsers from "../../components/Dashboard/Users/actionButtonsMultipleUsers";
import { styles } from "../../styles/dataTable_new";

function InviteUsersTable(props) {
  const {
    classes,
    hideColumnsIndexes = [],
    headings,
    rowsData,
    allowActionButtons,
    ActionButtons,
    onRowClick = () => {},
  } = props;
  const [data, setData] = useState();
  const [selected, setSelected] = React.useState([]);

  const selectedItem = (val) => {
    setData(val);
    onRowClick(val);
  };

  const handleClick = (row) => {
    selectedItem(row);
    const selectedIndex = selected.indexOf(row[2]);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row[2]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsData.map((n) => n[2]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const rowCount = rowsData.length;
  const numSelected = selected.length;

  const TopToolbar = () => {
    return rowCount ? (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
          }),
        }}>
        {numSelected > 0 && (
          <>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div">
              {numSelected} selected
            </Typography>
            <Tooltip title="Options">
              <IconButton size="large">
                {/* <MoreVert /> */}
                <ActionButtonsMultipleUsers
                  classes={classes}
                  selected={selected}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Toolbar>
    ) : null;
  };

  return (
    <div className={classes.mainrapper}>
      <div className={classes.fieldswrapper}>
        <TopToolbar />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {rowCount ? (
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all users",
                    }}
                  />
                ) : null}

                {headings
                  ? headings.map((name, index) =>
                      hideColumnsIndexes.indexOf(index) === -1 ? (
                        <TableCell key={name} align="center">
                          {name}
                        </TableCell>
                      ) : null,
                    )
                  : ""}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData.map((row, index) => {
                const isItemSelected = isSelected(row[2]);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={row[2]}
                    onClick={() => handleClick(row)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {row.map((val, index) =>
                      hideColumnsIndexes.indexOf(index) === -1 ? (
                        <TableCell align="center" key={index}>
                          {val}
                        </TableCell>
                      ) : null,
                    )}
                    {allowActionButtons === true ? (
                      <TableCell
                        onClick={(event) => event.stopPropagation()}
                        align="center">
                        <ActionButtons
                          classes={classes}
                          data={data}
                          rowData={row}
                        />
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default withStyles(styles)(InviteUsersTable);
