import { createSlice } from "@reduxjs/toolkit";
import { CustomMaterialService } from "../../services/customMaterialService";
import { showMessage } from "../../store/Revalu/notificationSlice";

// export const upload = (formData) => async (dispatch) => {
//   dispatch(setUploadLoading(true))
//   return LCAService.upload(formData)
//     .then((res) => {
//       return res
//     })
//     .catch((errors) => {
//       return errors
//     })
//     .finally(() => {
//       dispatch(setUploadLoading(false))
//     })
// }
// export const download = (data) => async (dispatch) => {
//   return LCAService.download(data)
//     .then((res) => {
//       return res
//     })
//     .catch((errors) => {
//       return errors
//     })
// }
export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return CustomMaterialService.list(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const addUUIDInCustomMaterial = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return CustomMaterialService.addUUIDInCustomMaterial(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getEmail = (id) => async (dispatch) => {
  // dispatch(setLoading(true))
  return CustomMaterialService.getEmail(id)
    .then((res) => {
      return res;
      // dispatch(setEmail(res))
    })
    .catch((errors) => {});
  // .finally(() => {
  //   dispatch(setLoading(false))
  //   return true
  // })
};

export const getById = (id) => async (dispatch) => {
  return CustomMaterialService.getById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deleteMaterial = (id) => async (dispatch) => {
  return CustomMaterialService.deleteMaterial(id)
    .then((res) => {
      dispatch(
        showMessage({
          message: res?.description,
        }),
      );
      window.location.reload();
      return res;
    })
    .catch((errors) => {
      return false;
    });
};

export const addMaterial = () => async (dispatch) => {
  return CustomMaterialService.addMaterial()
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return false;
    });
};

export const setCustomMaterialStatus = (model) => async (dispatch) => {
  const { id, status } = model;
  return CustomMaterialService.setCustomMaterialStatus(id, status)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return false;
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  recordLoading: false,
  importLoading: false,
  uploadLoading: false,
  searchValue: "",
  importProcessStatus: null,
  dataEmail: [],
};

const customMaterialSlice = createSlice({
  name: "customMaterial/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setImportProcessStatus: (state, action) => {
      state.importProcessStatus = action.payload;
    },
    setRecordLoading: (state, action) => {
      state.recordLoading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
      // state.originalData = action.payload
    },
    setEmail: (state, action) => {
      state.dataEmail = [action.payload];
    },
    // searchData: (state, action) => {
    //   let { search } = action.payload
    //   if (!search) {
    //     state.data = state.originalData
    //     return
    //   }
    //   search = search.toLowerCase()
    //   const searchData = state.originalData.content.filter((f) => {
    //     return (
    //       f.processInformation?.dataSetInformation?.UUID.toLowerCase().indexOf(
    //         search,
    //       ) > -1 ||
    //       f.processInformation?.dataSetInformation?.name?.baseName[0]?.value
    //         .toLowerCase()
    //         .indexOf(search) > -1
    //     )
    //   })
    //   state.data = {
    //     content: searchData,
    //     totalPages: 1,
    //     totalElements: searchData.length,
    //   }
    // },
    searchData: (state, action) => {
      const { search } = action.payload;
      state.searchValue = search;
    },
    setImportLoading: (state, action) => {
      state.importLoading = action.payload;
    },
    setUploadLoading: (state, action) => {
      state.uploadLoading = action.payload;
    },
    modifyRecordInState: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.data[index] = action.payload;
      }
    },
    removeRecordFromStateByName: (state, action) => {
      const name = action.payload;
      const indexToRemove = state.data.findIndex((f) => f.name === name);
      if (indexToRemove > -1) {
        state.data.splice(indexToRemove, 1);
      }
    },
    resetState: (state) => {
      state.recordLoading = false;
    },
  },
});

export const {
  setLoading,
  setRecordLoading,
  setData,
  modifyRecordInState,
  removeRecordFromStateByName,
  resetState,
  setImportLoading,
  setUploadLoading,
  searchData,
  setImportProcessStatus,
  setEmail,
} = customMaterialSlice.actions;

export default customMaterialSlice.reducer;
