export const styles = (theme) => ({
  navbarWrapper: {
    background: theme.palette.menu.background,
    padding: "11px 22px",
    width: "auto",
    borderBottom: "1px solid " + theme.palette.menu.border,
  },
  headingWithMenu: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      fontSize: 17,
      color: theme.palette.primary.heading,
      margin: 0,
      width: "100%",
    },
  },
  navIcons: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      color: "#c3cad9",
      marginLeft: 17,
      cursor: "pointer",
    },
  },
});
