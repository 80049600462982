import React, { useState, useEffect } from "react";
import { styles } from "../../../../../styles/Dashboard/data";
import { styles as dataTableStyles } from "../../../../../styles/dataTable";
import {
  importLCA,
  getOekobaudatList,
  searchData,
  getImportProcessStatus,
} from "../../../../../store/LCA/lcaSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { showMessage } from "../../../../../store/Revalu/notificationSlice";
import MUIDataTable from "mui-datatables";
import UploadEPDs from "./uploadEPDs";
import { withMultipleStyles } from "../../../../../styles/withMultipleStyles";
import Search from "../../Search";
import ActionButtons from "./actionButtons";
import CloseIcon from "@mui/icons-material/Close";
import ViewDetails from "./viewDetails";

const OekobaudatEPDs = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const dispatch = useDispatch();
  const { data, loading, importLoading, importProcessStatus, searchValue } =
    useSelector(({ lca }) => lca.slice);
  const { totalElements } = data;

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    dispatch(getOekobaudatList({ pageNo, pageSize, searchValue }));
  }, [pageNo, pageSize, searchValue]);

  const handleDownload = (type) => {};

  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
  };

  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };

  const headers = [
    { name: "UUID", options: { sort: false } },
    { name: "Name", options: { sort: false } },
    { name: "Platform", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={() => {}}
              onDownload={handleDownload}
              onViewDetails={handleViewDetails}
              rowData={value}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m?.processInformation?.dataSetInformation?.UUID,
      String(m?.processInformation?.dataSetInformation?.name?.baseName),
      m.platformName,
      m,
    ]);
  };

  const onResponse = (res) => {
    if (!res) {
      dispatch(showMessage({ message: "No response", variant: "error" }));
      return false;
    }
    const { status, statusText } = res;
    if (Number(status) === 400) {
      dispatch(
        showMessage({
          message: statusText || "An uknown error occurred",
          variant: "error",
        }),
      );
    } else if (typeof res === "string") {
      dispatch(
        showMessage({
          message: res,
        }),
      );
    }
  };

  const handleImport = async () => {
    try {
      const res = await dispatch(importLCA());
      onResponse(res);
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    }
  };
  const { classes } = props;

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [7, 10, 12],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search Here..."
        />
      );
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div className={classes.pageWrapper}>
      <UploadEPDs />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          {importProcessStatus?.processing && (
            <div className={classes.notification}>
              Import process is in progress.
            </div>
          )}
        </div>
      </div>

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Oekobaudat EPDs"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>EPD Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}
    </div>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(OekobaudatEPDs);
