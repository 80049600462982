import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";

export const Search = (props) => {
  const dispatch = useDispatch();
  const { classes, onSearch, placeholder } = props;
  const [text, setText] = useState("");
  const handleSearch = (val) => {
    dispatch(onSearch({ search: val }));
  };
  const handleChange = (e) => {
    setText(e.target.value);
    handleSearch(e.target.value);
  };
  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSearch(text);
    }
  };

  return (
    <div className={classes.searchWrapper}>
      <TextField
        id="search-users"
        variant="outlined"
        size="small"
        autoFocus
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
    </div>
  );
};

export default Search;
