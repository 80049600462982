import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Faq from "./Support/index";
import DrawerSideBar from "../../../common/Drawer/drawer";
import { getSupportPageData } from "../../../store/Support/supportSlice";
import { useSelector, useDispatch } from "react-redux";
import AddTile from "./createTileForm";

const Support = (props) => {
  const [isCSVMode, setIsCSVMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();

  const supportDetail = useSelector(
    ({ support }) => support.supportAll.supportData,
  );
  const supportUpdateDetail = useSelector(
    ({ support }) => support.supportAll.supportUpdateData,
  );

  const alertConfirmation = useSelector(
    ({ alert }) => alert.alertSlice.alertConfirm,
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        params: {
          langaugeCode: "en",
        },
      };
      const isValid = await dispatch(getSupportPageData(data));
      return isValid;
    };
    fetchData();

    supportUpdateDetail.length !== 0 ? setIsCreateMode(true) : "";
  }, [dispatch, supportUpdateDetail, alertConfirmation]);

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">FAQs/Support</h1>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={() => setIsCreateMode(!isCreateMode)}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            {supportUpdateDetail.length !== 0 ? "Update Here" : "Add new Topic"}
          </Button>
        </div>
      </div>
      <Faq
        supportDetail={supportDetail}
        alertConfirmation={alertConfirmation}
      />
      {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          {isCSVMode ? (
            ""
          ) : (
            <AddTile
              supportUpdateDetail={supportUpdateDetail}
              supportDetail={supportDetail}
            />
          )}
        </DrawerSideBar>
      )}
    </div>
  );
};

export default withStyles(styles)(Support);
