import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import { getAllSeller } from "../../../store/Seller/sellerSlice";
import { useSelector, useDispatch } from "react-redux";

const AllSeller = (props) => {
  const [allowActionButtons, setAllowActionButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();

  const viewAllSeller = useSelector(
    ({ seller }) => seller.sellerAll.sellerData,
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = {};
      await dispatch(getAllSeller(data));
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const headings = [
    "ID",
    "Seller Name",
    "UserName",
    "User Type",
    "Email",
    "User Status",
  ];
  const rowsData = viewAllSeller.map((item) => {
    return [
      item.id,
      item.sellerName,
      item.username,
      item.userType,
      item.email,
      String(item.userToken.active),
    ];
  });

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">All Sellers</h1>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
      />
      {isLoading && <span>Loading Sellers</span>}
    </div>
  );
};

export default withStyles(styles)(AllSeller);
