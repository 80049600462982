import { createSlice } from "@reduxjs/toolkit";
import { AdminUserService } from "../../services/adminUserService";

export const getAllAdminUser = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return AdminUserService.getAllAdminUsersData(data)
    .then((res) => {
      dispatch(adminUserSuccess(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

// METHODS FOR ADD/DELETE API KEY
export const addAPIKey =
  ({ apiKey, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await AdminUserService.addAPIKey({ apiKey, userId });
      dispatch(getAllAdminUser());
      return res;
    } catch (ex) {
      console.error(ex);
      throw ex;
    } finally {
      dispatch(setLoading(false));
    }
  };
export const deleteAPIKey =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await AdminUserService.deleteAPIKey({ userId });
      dispatch(getAllAdminUser());
      return res;
    } catch (ex) {
      console.error(ex);
      throw ex;
    } finally {
      dispatch(setLoading(false));
    }
  };

// : METHODS FOR ADD/DELETE API KEY

export const createAdminUser = (data) => async (dispatch) => {
  dispatch(createAdminUserLoading());
  return AdminUserService.createAdminUser(data)
    .then(async (res) => {
      dispatch(createAdminUserSuccess(res));
      AdminUserService.getAllAdminUsersData(data).then((res) => {
        dispatch(adminUserSuccess(res));
      });
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(completeCreateAdminUser());
      return true;
    });
};
export const updateAdminUser = (data, id) => async (dispatch) => {
  dispatch(createAdminUserLoading());
  return AdminUserService.updateAdminUser(data, id)
    .then(async (res) => {
      dispatch(createAdminUserSuccess(res));
      const { data, status } = res;
      if (Boolean(data) && Number(status) === 200) {
        dispatch(updateAdminUserFunc(data));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(completeCreateAdminUser());
    });
};
export const deleteAdminUser = (userId) => async (dispatch) => {
  dispatch(setLoading(true));
  return AdminUserService.deleteAdminUser(userId)
    .then(async (res) => {
      dispatch(deleteAdminUserSuccess(userId));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  data: [],
  orderItems: [],
  loading: false,
  createAdminUserSuccess: false,
  createAdminUserLoading: false,
  adminUserData: {},
  selected: null,
};

const adminUserSlice = createSlice({
  name: "adminUser/listing",
  initialState,
  reducers: {
    adminUserSuccess: (state, action) => {
      state.success = true;
      state.data = action.payload ? [...action.payload.data] : [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    createAdminUserLoading: (state) => {
      state.createAdminUserLoading = true;
    },
    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data[index];
      }
    },
    resetSelected: (state) => {
      state.selected = null;
    },
    createAdminUserSuccess: (state, action) => {
      state.createAdminUserSuccess = true;
      state.adminUserData = action.payload.data;
    },
    completeCreateAdminUser: (state) => {
      state.createAdminUserLoading = false;
    },
    updateAdminUserFunc: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.data[index] = action.payload;
      }
    },
    deleteAdminUserSuccess: (state, action) => {
      const userId = action.payload;
      const indexToRemove = state.data.findIndex((f) => f.id === userId);
      if (indexToRemove > -1) {
        state.data.splice(indexToRemove, 1);
      }
    },
    resetState: (state) => {
      state.createAdminUserSuccess = false;
      state.adminUserData = {};
      state.createAdminUserLoading = false;
    },
  },
});

export const {
  adminUserSuccess,
  completeCreateAdminUser,
  createAdminUserLoading,
  createAdminUserSuccess,
  resetState,
  setLoading,
  deleteAdminUserSuccess,
  updateAdminUserFunc,
  setSelected,
  resetSelected,
} = adminUserSlice.actions;

export default adminUserSlice.reducer;
