import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import UserContext from "../context/UserContext";
import { I18nPropvider, LOCALES } from "../i18nProvider";
import PrivateRoute from "../config/privateRoute";

import Login from "./Login/login";
import Dashboard from "./Dashboard/dashboard";
import RevaluNotification from "../common/notification/NotificationCard";

import { Routes } from "../common/Constants/routes";

import { getSession } from "../modules/AuthLibrary";
const App = () => {
  const authToken = getSession();
  const { selectedTheme } = useContext(UserContext);

  return (
    <>
      <I18nPropvider locale={LOCALES.ENGLISH}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={selectedTheme}>
              <CssBaseline />
              <Switch>
                <Route path={Routes.login} component={Login} />
                <Route path={Routes.user.default} component={Dashboard} />
                <PrivateRoute
                  granted={authToken ? true : false}
                  exact
                  path={Routes.user.default}
                  component={Dashboard}
                />
                <Redirect to={Routes.user.default} />
              </Switch>
              <RevaluNotification />
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </I18nPropvider>
    </>
  );
};

export default App;
