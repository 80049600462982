import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    background: theme.palette.primary.bg,
    border: "1px solid " + theme.palette.footer.border,
    padding: "22px 8px",
    "& .footerContent": {
      maxWidth: 1200,
      margin: "auto",
    },
  },
  leftWrapper: {
    width: "100%",
    "& h1": {
      fontSize: 12,
      color: theme.palette.primary.fontColor,
      margin: 0,
    },
  },
  rightWrapper: {
    textAlign: "right",
    "& a": {
      fontSize: 12,
      color: theme.palette.primary.fontColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& svg": {
        fontSize: 12,
      },
    },
  },
  copyrightText: {
    fontSize: 11,
    color: theme.palette.primary.fontColor,
    textAlign: "center",
    marginBottom: 0,
    marginTop: 22,
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerWrapper}>
      <Grid container className="footerContent">
        <Grid item xs={3}>
          <div className={classes.leftWrapper}>
            <h1>I-ways</h1>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.leftWrapper}>
            <h1>I-ways</h1>
            <h1>Contact Info</h1>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.rightWrapper}>
            <NavLink to={"/"}>Terms and Conditions</NavLink>
            <NavLink to={"/"}>Privacy policy</NavLink>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.rightWrapper}>
            <NavLink to={"/"}>
              Website
              <OpenInNewIcon />
            </NavLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.copyrightText}>
            Copyright Iways. All Rights Reserved
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
