import { createSlice } from "@reduxjs/toolkit";
import { MaterialTagsService } from "../../services/materialTagsService/index";

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return MaterialTagsService.list(data)
    .then((res) => {
      dispatch(setMaterialTagsData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getTags = (data) => async (dispatch) => {
  return MaterialTagsService.getTags(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const create = (data) => async (dispatch) => {
  return MaterialTagsService.create(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const update = (id, data) => async (dispatch) => {
  return MaterialTagsService.update(id, data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getTagOfMaterial = (id) => async (dispatch) => {
  return MaterialTagsService.getTagOfMaterial(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const addTagInMaterial = (tagMaterialTagDto) => async (dispatch) => {
  return MaterialTagsService.addTagInMaterial(tagMaterialTagDto)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deleteTagInMaterial = (tagMaterialTagDto) => async (dispatch) => {
  return MaterialTagsService.deleteTagInMaterial(tagMaterialTagDto)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deleteById = (tagMaterialTagDto) => async (dispatch) => {
  return MaterialTagsService.deleteById(tagMaterialTagDto)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    selected: null,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  searchValue: "",
  elementsData: "",
  selected: null,
};

const materialTags = createSlice({
  name: "mappedEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setMaterialTagsData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },

    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data.content[index];
      }
    },

    resetSelected: (state, action) => {
      state.selected = null;
    },
  },
});

export const { setLoading, setMaterialTagsData, setSelected, resetSelected } =
  materialTags.actions;

export default materialTags.reducer;
