import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  getCategoryHierarchyID,
  getElements,
  getBroaderCategories,
  getSubCategory,
  submitCategoryHierarchy,
  getById,
} from "../../../../store/MappedEDPs/mappedEPDSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import PasswordField from "../../../common/Fields/PasswordField"
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const CategoryHierarchyForm = ({
  mappedEdpID,
  qualityStatusValue,
  onClose = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [elementsValue, setElementsValue] = useState("");
  const [broaderCategoriesValue, setBroaderCategoriesValue] = useState("");
  const [subCategoriesValue, setSubCategoriesValue] = useState("");
  const [getElementsID, setGetElementsID] = useState("");
  const [getBroaderCategoriesID, setGetBroaderCategoriesID] = useState("");
  const [getSubCategoriesID, setGetSubCategoriesID] = useState("");
  const [viewAllData, setViewAllData] = useState("");
  const history = useHistory();
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice);
  const { elementsData, broaderCategoriesData, subCategoryData } = useSelector(
    ({ mappedEPD }) => mappedEPD.slice,
  );
  // const id = qualityStatusValue?.id
  const defaultValues = {
    name: viewAllData?.elementName || "",
  };

  useEffect(async () => {
    const res = await dispatch(getById(mappedEdpID));
    if (res && Boolean(res.length)) {
      setViewAllData(res[0]);
    }
    // setElementsValue(viewAllData?.elementName)

    dispatch(getElements());
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  const { control, formState, handleSubmit, setError, reset } = useForm({
    // mode: "onChange",
    // defaultValues,
    // resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  const onResponse = (res) => {
    const { id, status, data } = res;
    if (Number(status) === 400) {
      dispatch(showMessage({ message: "Error", variant: "error" }));
      onClose();
    }
    dispatch(showMessage({ message: "Submitted successfully" }));
    onClose();
    window.location.reload();
  };

  const onSubmit = async (model) => {
    const data = {
      getElementsID,
      getBroaderCategoriesID,
      getSubCategoriesID,
    };
    const res = await dispatch(getCategoryHierarchyID(data, history));
    if (res.id) {
      const ids = {
        categoryHierarchyId: res.id,
        dataSourceId: mappedEdpID,
      };
      const responce = await dispatch(submitCategoryHierarchy(ids, history));
      onResponse(responce);
    }
  };

  const onChangeElements = (event) => {
    setElementsValue(event.target.value);
  };

  const onChangeBroaderCategories = (event) => {
    setBroaderCategoriesValue(event.target.value);
  };
  const onChangeSubCategories = (event) => {
    setSubCategoriesValue(event.target.value);
  };
  const selectedElementValue = (data) => {
    dispatch(getBroaderCategories(data.id));
    setGetElementsID(data.id);
  };

  const selectedBroaderCategoriesValue = (data) => {
    dispatch(getSubCategory(data.id));
    setGetBroaderCategoriesID(data.id);
  };

  const selectedSubCategoriesValue = (data) => {
    setGetSubCategoriesID(data.id);
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Elements*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      elementsValue
                        ? () => elementsValue
                        : () => viewAllData?.elementName
                    }
                    value={
                      elementsValue ? elementsValue : viewAllData?.elementName
                    }
                    label="Elements"
                    onChange={onChangeElements}>
                    {elementsData ? (
                      elementsData?.content.map((item) => (
                        <MenuItem
                          value={item.element.elementName}
                          key={item.id}
                          onClick={() => selectedElementValue(item.element)}>
                          {item.element.elementName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={"select"}>Select Element</MenuItem>
                    )}
                  </Select>
                )}
              />
              <br />
              <br />
              {broaderCategoriesData && (
                <>
                  <label className={classes.label}>Broader Categories*</label>
                  <Controller
                    name="broader_categories"
                    control={control}
                    className="textfield"
                    render={({ field }) => (
                      <Select
                        error={!!errors.name}
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        displayEmpty
                        renderValue={
                          elementsValue === viewAllData?.elementName
                            ? () => viewAllData?.broaderCategoryName
                            : () => broaderCategoriesValue
                        }
                        value={
                          broaderCategoriesValue ? broaderCategoriesValue : ""
                        }
                        label="Broader Categories"
                        onChange={onChangeBroaderCategories}>
                        {broaderCategoriesData &&
                          broaderCategoriesData.map((item) => (
                            <MenuItem
                              value={item.broaderCategoryName}
                              key={item.id}
                              onClick={() =>
                                selectedBroaderCategoriesValue(item)
                              }>
                              {item.broaderCategoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </>
              )}
              <br />
              <br />
              {subCategoryData && (
                <>
                  <label className={classes.label}>Sub Categories*</label>
                  <Controller
                    name="sub_categories"
                    control={control}
                    className="textfield"
                    render={({ field }) => (
                      <Select
                        error={!!errors.name}
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        value={subCategoriesValue ? subCategoriesValue : ""}
                        label="Sub Categories"
                        onChange={onChangeSubCategories}>
                        {subCategoryData &&
                          subCategoryData.map((item) => (
                            <MenuItem
                              value={item.subCategoryName}
                              key={item.id}
                              onClick={() => selectedSubCategoriesValue(item)}>
                              {item.subCategoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          // disabled={!isValid || recordLoading}
          className={classes.submitButton}>
          {recordLoading ? "Saving..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default CategoryHierarchyForm;
