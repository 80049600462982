import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  create,
  update,
  resetState,
} from "../../../store/ObjectType/objectTypeSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PasswordField from "../../../common/Fields/PasswordField";
import { showMessage } from "../../../store/Revalu/notificationSlice";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const ObjectTypeForm = ({ currentRecord, onClose = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice);
  const id = currentRecord?.id;
  const defaultValues = {
    name: currentRecord?.name || "",
  };

  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  const onResponse = (res) => {
    const { id, status, data } = res;
    if (Boolean(id)) {
      dispatch(showMessage({ message: "Saved successfully" }));
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        !data ||
        (data &&
          data["database exception"]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf("duplicate") > -1)
      ) {
        dispatch(
          showMessage({
            message: "Attribute already exists",
            variant: "error",
          }),
        );
      }
    }
  };

  const onSubmit = async (model) => {
    if (Boolean(id)) {
      const res = await dispatch(update(model, id, history));
      onResponse(res);
    } else {
      const res = await dispatch(create(model, history));
      onResponse(res);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Name*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    placeholder="Name"
                    required
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || recordLoading}
          className={classes.submitButton}>
          {recordLoading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  );
};

export default ObjectTypeForm;
