import { combineReducers } from "@reduxjs/toolkit";
import userLogin from "./loginSlice";
import userRegister from "./registerSlice";
import userAccount from "./userSlice";

const userAuthReducers = combineReducers({
  userAccount,
  userLogin,
  userRegister,
});

export default userAuthReducers;
