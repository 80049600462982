import axios from "axios";
import { BASE_URL_PRODUCT } from "../../config/config";
/* eslint-disable camelcase */

class OrderService {
  getAllOrderData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_PRODUCT + "orders/all", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}

const instance = new OrderService();

export default instance;
