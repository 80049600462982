import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";

import { styles } from "../../../../styles/Dashboard/data";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { withMultipleStyles } from "../../../../styles/withMultipleStyles";
import MUIDataTable from "mui-datatables";
import {
  deleteMappedDocument,
  deleteReferenceMappedDocument,
  getMappedDocuments,
  getMappedReferencedDocuments,
} from "../../../../store/MappedEDPs/mappedEPDSlice";
import UploadDocument from "./uploadDocument";

const ViewDocuments = (props) => {
  const dispatch = useDispatch();
  const { id, classes, onClose } = props;
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [currentRecord, setCurrentRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const refresh = () => setUpdate((prev) => setUpdate(!prev));

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      const res = await dispatch(getMappedDocuments(id));
      const res2 = await dispatch(getMappedReferencedDocuments(id));
      let allData;
      if (Array.isArray(res2)) {
        allData = res.concat(res2);
      } else {
        allData = res;
      }

      setLoading(false);
      if (allData.length) {
        setData(allData);
      } else {
        setLoading(false);
        dispatch(showMessage({ message: "No Documents Found" }));
        setData([]);
      }
    };
    fetchDocuments();
  }, [id, dispatch, update]);

  const deleteDocument = async (values) => {
    setLoading(true);
    const res = await dispatch(deleteMappedDocument(values?.id));
    setLoading(false);
    if (res) {
      dispatch(showMessage({ message: "Deleted Successfully" }));
      refresh();
    } else {
      setLoading(false);
      refresh();
      dispatch(showMessage({ message: "No Document Found" }));
    }
  };

  const deleteReferenceDocument = async (values) => {
    setLoading(true);
    const res = await dispatch(deleteReferenceMappedDocument(values?.id));
    setLoading(false);
    if (res) {
      dispatch(showMessage({ message: "Deleted Successfully" }));
      refresh();
    } else {
      setLoading(false);
      refresh();
      dispatch(showMessage({ message: "No Document Found" }));
    }
  };

  const hideModal = () => {
    setSelectedId(null);
  };

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: 22,
      boxShadow: "0px 2px 5px #343D4F08",
      borderRadius: 12,
      "& .title": {
        fontSize: 17,
        fontWeight: 500,
        color: theme.palette.primary.fontColor,
      },
    },
    formWrapper: {
      marginBottom: 13,
      "& .textfield": {
        border: "1px solid " + theme.palette.drawer.border,
        borderRadius: 13,
        background: theme.palette.drawer.textFieldBg,
        "& input": {
          padding: "7px 18px",
          height: 11,
        },
      },
    },
    label: {
      color: theme.palette.primary.fontColor,
      fontWeight: 500,
      fontSize: 16,
      display: "block",
      marginBottom: 5,
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: 13,
      "& span": {
        padding: 0,
      },
    },
  }));

  const headers = [
    { name: "Username", options: { sort: true } },
    { name: "Datasource", options: { sort: false } },
    { name: "Source", options: { sort: false } },
    { name: "Type", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <div className={classes.actionBtns}>
              <Button
                color="primary"
                style={{ width: "100px", backgroundColor: "darkgreen" }}
                variant="contained"
                onClick={() => {
                  window.open(value?.uri, "_blank");
                }}>
                View
              </Button>{" "}
              <Button
                color="primary"
                style={{ width: "100px" }}
                variant="contained"
                disabled={value.type === "ref"}
                onClick={() => {
                  setSelectedId(value?.id);
                  setCurrentRecord(data.filter((m) => m.id === value?.id)[0]);
                }}>
                Edit
              </Button>{" "}
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (value.type === "ref") {
                    deleteReferenceDocument(value);
                  } else {
                    deleteDocument(value);
                  }
                }}>
                Delete
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const getParsedData = () => {
    return data?.map((m) => [
      m.adminUserName,
      m?.dataSourceId,
      m?.source || m?.name,
      m?.type || "Reference Document",
      {
        id: m?.id,
        uri: m?.documentUri || m?.pdfDocument,
        type: m?.type ? "" : "ref",
      },
    ]);
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  };

  return (
    <div className={classes.pageWrapper}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title=""
            columns={headers}
            data={getParsedData()}
            options={{ ...options }}
          />
        </div>
      </div>
      {Boolean(selectedId) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={Boolean(selectedId)}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>Update Document</Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <UploadDocument currentRecord={currentRecord} onClose={hideModal} />
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(ViewDocuments);
