import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import DrawerSideBar from "../../../common/Drawer/drawer";
import UploadCSV from "./uploadCSV";
import CreateForm from "./createForm";
import { getAllOrders } from "../../../store/Orders/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import ActionButtons from "./actionButtons";

const AllOrder = (props) => {
  const [isCSVMode, setIsCSVMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [allowActionButtons, setAllowActionButtons] = useState(true);
  const { classes } = props;

  const dispatch = useDispatch();

  const viewAllOrders = useSelector(({ orders }) => orders.orderAll.orderData);

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      const isValid = await dispatch(getAllOrders(data));
      // setLoading(false)
      return isValid;
    };

    fetchData();
  }, [dispatch]);

  const headings = [
    "Order ID",
    "UserName",
    "Product ID",
    "Email",
    "Total Ammount",
    "Address",
    "Product Source",
    "Status",
    "Date",
  ];
  const rowsData = viewAllOrders.map((item) => {
    return [
      item.id,
      item.cartItems[0].sellerUsername,
      item.cartItems[0].itemId,
      item.shippingAddress.email,
      (item.cartItems[0].currency === "EUR"
        ? "€"
        : item.cartItems[0].currency === "USD"
          ? "$"
          : item.cartItems[0].currency) +
        " " +
        item.totalAmount,
      (item.shippingAddress?.city ? item.shippingAddress.city + "," : "") +
        " " +
        item.shippingAddress.country,
      item.cartItems[0].productSource,
      item.status,
      item.createdDate,
    ];
  });

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">All Orders</h1>
      <div className={classes.headerContent}>
        <Button
          style={{ marginRight: 13 }}
          className={isCSVMode ? "lightButton" : "activeButton"}
          onClick={() => setIsCSVMode(false)}>
          Form
        </Button>
        <Button
          className={isCSVMode ? "activeButton" : "lightButton"}
          onClick={() => setIsCSVMode(true)}>
          CSV File
        </Button>
        <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={() => setIsCreateMode(!isCreateMode)}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            Add new one
          </Button>
        </div>
      </div>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
        ActionButtons={ActionButtons}
      />
      {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          {isCSVMode ? <UploadCSV /> : <CreateForm />}
        </DrawerSideBar>
      )}
    </div>
  );
};

export default withStyles(styles)(AllOrder);
