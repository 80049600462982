import axios from "axios";
import { BASE_URL_V2 } from "../../config/config";
/* eslint-disable camelcase */

class MaterialTagsService {
  getTags = () => {
    const pageNo = 0;
    const pageSize = 1000;
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "tags/allPaginatedTags?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  list = (data) => {
    const { pageNo = 0, pageSize = 10 } = data || {};
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "materialTag/allPaginatedMaterialTags?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  create = (materialTagDto) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + "materialTag/create", materialTagDto)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  update = (dataSourceId, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "materialTag/update?dataSourceId=" +
            dataSourceId,
          data,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  delete = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_V2("epd") + "materialTag/delete?dataSourceId=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getTagOfMaterial = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "materialTag/getTagOfMaterial?dataSourceId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  addTagInMaterial = (tagMaterialTagDto) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") + "materialTag/addTagInMaterial",
          tagMaterialTagDto,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteTagInMaterial = (tagMaterialTagDto) => {
    // console.log(tagMaterialTagDto, "tagMaterialTagDto")
    const url = `${BASE_URL_V2("epd")}materialTag/deleteTagFromMaterial`;

    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          data: tagMaterialTagDto, // Include the data in the request body
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteById = (dataSourceId) => {
    // console.log(dataSourceId, "dataSourceId")
    const url = `${BASE_URL_V2(
      "epd",
    )}materialTag/delete?dataSourceId=${dataSourceId}`;

    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}

const instance = new MaterialTagsService();

export default instance;
