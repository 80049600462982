import React, { useState } from "react";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { useDispatch } from "react-redux";
import { uploadCsv } from "../../../../store/Attributes/attributeSlice";

const UploadCSV = ({ onClose = () => {} }) => {
  const [file, setFile] = useState();
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("filePath", file);
    await dispatch(uploadCsv(formData));
    onClose();
  };
  return (
    <div style={{ textAlign: "center", marginBottom: "50px" }}>
      <h1>Attribute CSV Upload</h1>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <button
          onClick={(e) => {
            handleOnSubmit(e);
          }}>
          Upload
        </button>
      </form>
    </div>
  );
};

export default UploadCSV;
