import { createSlice } from "@reduxjs/toolkit";
import { CategoryHierarchyService } from "../../services/categoryHierarchyService";
import { showMessage } from "../Revalu/notificationSlice";
import { Routes } from "../../common/Constants/routes";

export const getAllElements = (data) => async (dispatch) => {
  return CategoryHierarchyService.getAllElements(data)
    .then((res) => {
      dispatch(elementsSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const getAllLevel1 = (data) => async (dispatch) => {
  return CategoryHierarchyService.getAllLevel1(data)
    .then((res) => {
      dispatch(level1Success(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const getAllLevel2 = (data) => async (dispatch) => {
  return CategoryHierarchyService.getAllLevel2(data)
    .then((res) => {
      dispatch(level2Success(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitAddElements = (data, history) => async (dispatch) => {
  return CategoryHierarchyService.addNewElement(data)
    .then((user) => {
      dispatch(elementsSuccess());
      dispatch(showMessage({ message: "New Element Added successfuly" }));
      history.push(Routes.category_hierarchy.elements);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitAddLevel1 = (data, history) => async (dispatch) => {
  return CategoryHierarchyService.submitAddLevel1(data)
    .then((user) => {
      dispatch(level1Success());
      dispatch(showMessage({ message: "New Category Added successfuly" }));
      history.push(Routes.category_hierarchy.level1);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitAddLevel2 = (data, history) => async (dispatch) => {
  return CategoryHierarchyService.submitAddLevel2(data)
    .then((user) => {
      dispatch(level2Success());
      dispatch(showMessage({ message: "New Category Added successfuly" }));
      history.push(Routes.category_hierarchy.level2);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const updateElements = (data, id, history) => async (dispatch) => {
  return CategoryHierarchyService.updateDataElement(data, id)
    .then((user) => {
      dispatch(elementsSuccess());
      dispatch(showMessage({ message: "Element Updated successfuly" }));
      history.push(Routes.category_hierarchy.elements);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const updateLevel1 = (data, id, history) => async (dispatch) => {
  return CategoryHierarchyService.updateLevel1(data, id)
    .then((user) => {
      dispatch(level1Success());
      dispatch(showMessage({ message: "Category Updated successfuly" }));
      history.push(Routes.category_hierarchy.level1);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const updateLevel2 = (data, id, history) => async (dispatch) => {
  return CategoryHierarchyService.updateLevel2(data, id)
    .then((user) => {
      dispatch(level2Success());
      dispatch(showMessage({ message: "Category Updated successfuly" }));
      history.push(Routes.category_hierarchy.level2);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const updateSelectedData = (data) => async (dispatch) => {
  return CategoryHierarchyService.updateSelectedData(data)
    .then((res) => {
      dispatch(selectedDataSuccess(res));
      return true;
    })
    .catch((errors) => {
      dispatch(selectedDataSuccess(errors));
      return false;
    });
};

export const deleteElement = (data) => async (dispatch) => {
  return CategoryHierarchyService.deleteElement(data)
    .then((res) => {
      dispatch(showMessage({ message: "Element Deleted successfuly" }));
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const deleteLevel1 = (data) => async (dispatch) => {
  return CategoryHierarchyService.deleteLevel1(data)
    .then((res) => {
      dispatch(showMessage({ message: "Category Deleted successfuly" }));
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const deleteLevel2 = (data) => async (dispatch) => {
  return CategoryHierarchyService.deleteLevel2(data)
    .then((res) => {
      dispatch(showMessage({ message: "Category Deleted successfuly" }));
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitRelationship = (data, history) => async (dispatch) => {
  return CategoryHierarchyService.submitAddRelationship(data)
    .then((user) => {
      dispatch(elementsSuccess());
      dispatch(showMessage({ message: "New RelationShip Added successfuly" }));
      history.push(Routes.category_hierarchy.relationship);
      // window.location.reload()
      return;
    })
    .catch((errors) => {
      return false;
    });
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  attributeData: [],
  updateData: [],
  elementsData: [],
  level1Data: [],
  level2Data: [],
};

const categoryHierarchySlice = createSlice({
  name: "attribute/listing",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true;
      state.product = action.payload;
    },
    attributeSuccess: (state, action) => {
      state.success = true;
      state.attributeData = action.payload ? [...action.payload] : [];
    },
    elementsSuccess: (state, action) => {
      state.success = true;
      state.elementsData = action.payload ? action.payload : [];
    },
    level1Success: (state, action) => {
      state.success = true;
      state.level1Data = action.payload ? action.payload : [];
    },
    level2Success: (state, action) => {
      state.success = true;
      state.level2Data = action.payload ? action.payload : [];
    },
    selectedDataSuccess: (state, action) => {
      state.success = true;
      state.updateData = action.payload ? [action.payload] : [];
    },
  },
});

export const {
  productSuccess,
  attributeSuccess,
  elementsSuccess,
  selectedDataSuccess,
  level1Success,
  level2Success,
} = categoryHierarchySlice.actions;

export default categoryHierarchySlice.reducer;
