import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import { list, resetSelected } from "../../../store/ObjectType/objectTypeSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateAdminUserForm from "./objectTypeForm";
import { Dialog, DialogTitle, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Delete, Edit } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import ActionButtons from "./actionButtons";

const AllObjectTypes = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [, setSelectedRow] = useState(null);
  const { classes } = props;

  const dispatch = useDispatch();

  const {
    data,
    loading,
    selected: currentRecord,
  } = useSelector(({ objectType }) => objectType.slice);
  const handleRowClick = (data) => {
    setSelectedRow(data);
  };
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const hideModal = () => {
    setShowModal(false);
    dispatch(resetSelected());
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      await dispatch(list(data));
    };

    fetchData();
  }, [dispatch]);

  const headings = ["ID", "Name"];
  const rowsData = data.map((item) => {
    return [item.id, item.name];
  });

  // const handleDelete = async (name) => {
  //   try {
  //     const r = window.confirm("Are you sure you want to delete?")
  //     if (!r) {
  //       return false
  //     }
  //     await dispatch(deleteByName(name))
  //     dispatch(showMessage({ message: "Record deleted" }))
  //   } catch (ex) {
  //     dispatch(
  //       showMessage({ message: ex.message ? ex.messge : ex, variant: "error" }),
  //     )
  //   }
  // }
  // const handleEdit = async (id) => {
  //   try {
  //     setCurrentRecord(data.find((f) => f.id === id))
  //     setShowModal(true)
  //   } catch (ex) {
  //     dispatch(
  //       showMessage({ message: ex.message ? ex.messge : ex, variant: "error" }),
  //     )
  //   }
  // }

  // const ActionButtons = ({ rowData: data }) => {
  //   return (
  //     <>
  //       <IconButton onClick={() => handleEdit(data[0])}>
  //         <Edit />
  //       </IconButton>
  //       <IconButton onClick={() => handleDelete(data[1])}>
  //         <Delete className="text-danger" />
  //       </IconButton>
  //     </>
  //   )
  // }

  return (
    <>
      <div className={classes.pageWrapper}>
        <h1 className="title">Object Types</h1>

        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : null}
        <div className={classes.headerContent}>
          <div className={classes.headerOptions}>
            <Button
              style={{ marginLeft: 13 }}
              variant="contained"
              color="primary"
              onClick={toggleModal}
              startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
              Add new
            </Button>
          </div>
        </div>
        <DataTable_New
          hideColumnsIndexes={[0]}
          headings={headings}
          rowsData={rowsData}
          allowActionButtons
          onRowClick={handleRowClick}
          ActionButtons={ActionButtons}
        />
      </div>
      {(showModal || currentRecord) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal || currentRecord}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {Boolean(currentRecord) ? "Update" : "Create"} Object Type
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateAdminUserForm
            currentRecord={currentRecord}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(AllObjectTypes);
