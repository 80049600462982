export const renderValueAttribute = (val, attribute) => {
  if (val instanceof Object) {
    const text = [];
    Object.keys(val).map((m) => {
      if (val[m] instanceof Object) {
        return renderValue(val[m], attribute);
      } else {
        return text.push(`${m}: ${val[m]}`);
      }
    });
    return text.join(", ");
  }
  if (typeof val == "boolean") {
    return val.toString();
  }

  if (attribute === "Custom Image") {
    return val.split("?")[0];
  }

  if (attribute == "epdUri") {
    const tempVal = val.split("?")[0];
    return tempVal.split("/").at(-1);
  }

  if (
    [
      "epdInsertDate",
      "epdUpdateDate",
      "discoverySpaceInsertDate",
      "dateInserted",
      "dateLastUpdated",
      "featuredManufacturerInsertDate",
      "EPD_VERSION_CHANGE_DATE",
    ].includes(attribute)
  ) {
    return new Date(parseInt(val)).toUTCString();
  }

  return val;
};

export const renderValue = (val) => {
  if (val instanceof Object) {
    const text = [];
    Object.keys(val).map((m) => {
      if (val[m] instanceof Object) {
        return renderValue(val[m]);
      } else {
        return text.push(`${m}: ${val[m]}`);
      }
    });
    return text.join(", ");
  }
  if (typeof val == "boolean") {
    return val.toString();
  }
  return val;
};
