import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSource } from "../store/Attributes/attributeSlice";
import axios from "axios";
import { BASE_URL_EPD } from "../config/config";

const useAttributes = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const getAttr = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL_EPD}extracted/getAllFieldNamesAndTypes`,
      );
      setData({
        attributes: data || {},
        attributesList: Object.keys(data) || [],
      });
    } catch (ex) {
      setData({
        attributes: {},
        attributesList: [],
      });
    }
  };
  useEffect(() => {
    getAttr();
  }, []);

  return data;
};

export default useAttributes;
