import React, { Component } from "react";
import List from "@mui/material/List";
import withStyles from "@mui/styles/withStyles";
import user_list from "./userSiderbarList";
import { Link } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import { styles } from "../../styles/sidebar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackwardIcon from "@mui/icons-material/ArrowBack";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Input";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick(item) {
    this.setState((prevState) => ({ [item]: !prevState[item] }));
  }

  handler(children) {
    const { classes } = this.props;
    const { state } = this;
    return children.map((subOption, index) => {
      if (!subOption.children) {
        return (
          <div key={index}>
            <p>
              <Link
                to={{
                  pathname: subOption.url || "/",
                  state: { title: subOption.label || children.label },
                }}
                className={`${classes.sidebarLinks} ${
                  classes[subOption.className]
                }`}
                key={subOption.label}>
                {subOption.icon}
                {this.state.isOpen && (
                  <span style={{ marginLeft: 11 }}>{subOption.label}</span>
                )}
              </Link>
            </p>
          </div>
        );
      }
      return (
        <div key={index}>
          <p>
            <List
              className={`${classes.sidebarLinks} ${
                classes[subOption.className]
              }`}
              key={subOption.label}
              onClick={() => this.handleClick(subOption.label)}>
              {subOption.icon}
              {this.state.isOpen && (
                <span style={{ marginLeft: 11 }}>{subOption.label}</span>
              )}
              {state.isOpen && (
                <>
                  {state[subOption.label] ? <ArrowDropUp /> : <ArrowDropDown />}
                </>
              )}
            </List>
          </p>
          <Collapse in={state[subOption.label]} timeout="auto" unmountOnExit>
            {this.handler(subOption.children)}
          </Collapse>
        </div>
      );
    });
  }

  onClose = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  logoutHandler = () => {
    localStorage.clear();
    localStorage.removeItem("jwt_access_token");
    window.location.replace("/login");
  };

  render() {
    const { classes } = this.props;
    const list = user_list;
    return (
      <div className={classes.sidebarWrapper}>
        {!this.state.isOpen ? (
          <ArrowForwardIcon className={classes.arrow} onClick={this.onClose} />
        ) : (
          <ArrowBackwardIcon
            className={classes.arrowActive}
            onClick={this.onClose}
          />
        )}
        <List>{this.handler(list)}</List>
        <List className={classes.sidebarLinks} onClick={this.logoutHandler}>
          <LogoutIcon />
          {this.state.isOpen && <span style={{ marginLeft: 11 }}>Logout</span>}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(Sidebar);
