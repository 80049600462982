import React from "react";
import { styles } from "../../styles/dashboard";
import withStyles from "@mui/styles/withStyles";
import { Redirect, Switch } from "react-router-dom";

import Footer from "../../common/Footer/Footer";
import Sidebar from "../../common/Sidebar/sidebar";
import Navbar from "../../common/Navbar/navbar";
import AllSeller from "./Users/viewAllSeller";
import AllBuyer from "./Users/viewAllBuyer";
import AllAdminUsers from "./Users/viewAllAdminUsers";
import AllObjectTypes from "./ObjectTypes/viewAllObjectTypes";
import OriginalEDPs from "./EDPManagement/OriginalEDPs/originalEDPs";
import MappedEPDs from "./EDPManagement/MappedEPDs/mappedEPDs";
import EcoPortalEPDs from "./EDPManagement/OriginalEDPs/EcoPortalEPDs/ecoportalEPDs";
import OekobaudatEPDs from "./EDPManagement/OriginalEDPs/OekobaudatEPDs/oekobaudatEPDs";
import AllOrder from "./Orders/viewAllOrders";
import AllSource from "./AttributeManagement/Source/viewAllSource";
import AllSemanticGroup from "./AttributeManagement/SemanticGroup/viewAllSemanticGroup";
import AllAttributes from "./AttributeManagement/Attribute/viewAllAttribute";
import AllMetadata from "./AttributeManagement/Metadata/viewAllMetadata";
import DashboardLanding from "./Landing/landing";
import Glossary from "./Pages/Glossary/data";
import Support from "./Pages/support";
import AllElements from "./CategoryHierarchy/Elements/viewAllElements";
import AllLevel1 from "./CategoryHierarchy/Level1/viewAllLevel1";
import AllLevel2 from "./CategoryHierarchy/Level2/viewAllLevel2";
import AllManufactureUsers from "../Dashboard/Manufacturer/manufactures";
import AllContacts from "../Dashboard/Contacts/contacts";
import AllSupplier from "../Dashboard/Supplier/supplier";
import RelationshipForm from "./CategoryHierarchy/Relationship/categoryHierarchyForm";
import CustomMaterial from "./CustomMaterial/customMaterialEPDs";

import { Routes } from "../../common/Constants/routes";

import PrivateRoute from "../../config/privateRoute";
import { getSession } from "../../modules/AuthLibrary";
import viewAllInvitedUsers from "./Users/viewAllInvitedUsers";
import viewAllRegisteredUsers from "./Users/viewAllRegisteredUsers";

import AllTags from "../Dashboard/EDPManagement/MaterialTags/tags.js";

const Dashboard = (props) => {
  const authToken = getSession();
  const { classes } = props;
  return (
    <div className={classes.mainWrapper}>
      <div style={{ display: "flex", minHeight: 830 }}>
        <Sidebar />
        <div>
          <Navbar />
          <Switch>
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.dashboard}
              component={DashboardLanding}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.adminUsers}
              component={AllAdminUsers}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.inviteUsers}
              component={viewAllInvitedUsers}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.platformUsers}
              component={viewAllRegisteredUsers}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.sellers}
              component={AllSeller}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.buyers}
              component={AllBuyer}
            />

            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.orders}
              component={AllOrder}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.attribute.source}
              component={AllSource}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.attribute.semanticGroup}
              component={AllSemanticGroup}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.attribute.attribute}
              component={AllAttributes}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.attribute.metadata}
              component={AllMetadata}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.support}
              component={Support}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.glossary}
              component={Glossary}
            />
            <PrivateRoute
              granted={Boolean(authToken)}
              exact
              path={Routes.objectTypes.all}
              component={AllObjectTypes}
            />

            <PrivateRoute
              granted={Boolean(authToken)}
              exact
              path={Routes.lca.originalEDPs}
              component={OriginalEDPs}
            />
            <PrivateRoute
              granted={Boolean(authToken)}
              exact
              path={Routes.lca.ecoPortalEDPs}
              component={EcoPortalEPDs}
            />
            <PrivateRoute
              granted={Boolean(authToken)}
              exact
              path={Routes.lca.oekobaudatEPDs}
              component={OekobaudatEPDs}
            />
            <PrivateRoute
              granted={Boolean(authToken)}
              exact
              path={Routes.lca.mappedEPDs}
              component={MappedEPDs}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.category_hierarchy.elements}
              component={AllElements}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.category_hierarchy.level1}
              component={AllLevel1}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.category_hierarchy.level2}
              component={AllLevel2}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.category_hierarchy.relationship}
              component={RelationshipForm}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.user.customMaterial}
              component={CustomMaterial}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.manufacturer.all}
              component={AllManufactureUsers}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.lca.tags}
              component={AllTags}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.manufacturer.contacts}
              component={AllContacts}
            />
            <PrivateRoute
              granted={!!authToken ? true : false}
              exact
              path={Routes.manufacturer.supplier}
              component={AllSupplier}
            />
            <Redirect to={Routes.user.dashboard} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Dashboard);
