import { createSlice } from "@reduxjs/toolkit";
import { ManufactureContactService } from "../../services/contactService";
import { showMessage } from "../Revalu/notificationSlice";

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureContactService.list(data)
    .then((res) => {
      dispatch(setContactData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const linking = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureContactService.linking(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getById = (id) => async (dispatch) => {
  return ManufactureContactService.getById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const create = (data) => async (dispatch) => {
  return ManufactureContactService.create(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const update = (id, data) => async (dispatch) => {
  return ManufactureContactService.update(id, data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const updateList = (data) => async (dispatch) => {
  return ManufactureContactService.list(data)
    .then((res) => {
      dispatch(setContactData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      return true;
    });
};

export const deleteById = (id) => async (dispatch) => {
  return ManufactureContactService.deleteById(id)
    .then((res) => {
      if (Boolean(res?.deletedCount)) {
        dispatch(removeRecord(id));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    selected: null,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  searchValue: "",
  elementsData: "",
  selected: null,
};

const contactSlice = createSlice({
  name: "mappedEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setContactData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    modifyRecord: (state, action) => {
      const { id, blocked } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content[index].blocked = blocked;
        state.originalData.content[index].blocked = blocked;
      }
    },
    removeRecord: (state, action) => {
      const id = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content.splice(index, 1);
        state.originalData.content.splice(index, 1);
      }
    },
    searchData: (state, action) => {
      const { search } = action.payload;
      state.searchValue = search;
    },
    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data.content[index];
      }
    },
    resetSelected: (state, action) => {
      state.selected = null;
    },
  },
});

export const {
  setLoading,
  setContactData,
  modifyRecord,
  setExportLoading,
  setExportProcessStatus,
  setExportProcessLoading,
  searchData,
  setElements,
  setBroaderCategories,
  setSubCategory,
  removeRecord,
  setSelected,
  resetSelected,
} = contactSlice.actions;

export default contactSlice.reducer;
