import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";

import { UserProvider } from "./context/UserContext";

import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

// reportWebVitals()
