import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  create,
  resetSelected,
  update,
} from "../../../store/Supplier/supplierSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { showMessage } from "../../../store/Revalu/notificationSlice";
import { useState } from "react";
import { handleUploadFile } from "../../../utils/fileUpload";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const CreateContactForm = ({
  currentRecord,
  onClose = () => {},
  refreshList = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const id = currentRecord?.id;
  const defaultValues = {
    contactName: currentRecord?.contactName || "",
    email: currentRecord?.email || "",
    country: currentRecord?.country || "",
    city: currentRecord?.city || "",
    street: currentRecord?.street || "",
    telephoneNo: currentRecord?.telephoneNo || "",
    zipCode: currentRecord?.zipCode || "",
    website: currentRecord?.website || "",
    vat: currentRecord?.vat || "",
  };

  const addNewSchema = yup.object().shape({
    contactName: yup.string().required(),
    email: yup.string().email("Invalid email").required(),
    country: yup.string(),
    city: yup.string(),
    street: yup.string(),
    telephoneNo: yup.string(),
    zipCode: yup.string(),
    website: yup.string(),
    vat: yup.string(),
  });

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onResponse = (res) => {
    if (!res?.status) {
      dispatch(
        showMessage({
          message: `The contact has been ${id ? "updated" : "created"}`,
        }),
      );
      dispatch(resetSelected());
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(
          showMessage({ message: "Email already exists", variant: "error" }),
        );
      }
    } else if (res?.status == 424) {
      setError("country", {
        type: "manual",
        message: "Invalid country",
      });
      dispatch(showMessage({ message: "Invalid country", variant: "error" }));
    }
  };

  const onSubmit = async (model) => {
    if (Boolean(id)) {
      const res = await dispatch(update(id, model));
      onResponse(res);
      refreshList();
    } else {
      const res = await dispatch(create(model));
      onResponse(res);
      refreshList();
    }
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Name*</label>
              <Controller
                name="contactName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-supplier"
                    {...field}
                    error={!!errors.contactName}
                    helperText={errors?.contactName?.message}
                    variant="outlined"
                    placeholder="Supplier Name"
                    required
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Email*</label>
              <Controller
                name="email"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-supplier"
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Email"
                    disabled={Boolean(id)}
                    inputProps={{ maxLength: 40 }}
                    required
                  />
                )}
              />
            </div>
          </Grid>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Country*</label>
            <Controller
              name="country"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-supplier"
                  {...field}
                  error={!!errors.country}
                  helperText={errors?.country?.message}
                  variant="outlined"
                  placeholder="country"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>City</label>
            <Controller
              name="city"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-supplier"
                  {...field}
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  variant="outlined"
                  placeholder="city"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>

          <div className={classes.formWrapper}>
            <label className={classes.label}>Street </label>
            <Controller
              name="street"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-supplier"
                  {...field}
                  error={!!errors.street}
                  helperText={errors?.street?.message}
                  variant="outlined"
                  placeholder="street"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>

          <div className={classes.formWrapper}>
            <label className={classes.label}>Zipcode </label>
            <Controller
              name="zipCode"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-supplier"
                  {...field}
                  error={!!errors.zipCode}
                  helperText={errors?.zipCode?.message}
                  variant="outlined"
                  placeholder="zipCode"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Telephone </label>
              <Controller
                name="telephoneNo"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-supplier"
                    {...field}
                    error={!!errors.telephoneNo}
                    helperText={errors?.telephoneNo?.message}
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 240 }}
                    placeholder="telephoneNo"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Website </label>
              <Controller
                name="website"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-supplier"
                    {...field}
                    error={!!errors.website}
                    helperText={errors?.website?.message}
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 240 }}
                    placeholder="website"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>VAT </label>
              <Controller
                name="vat"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-supplier"
                    {...field}
                    error={!!errors.vat}
                    helperText={errors?.vat?.message}
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 240 }}
                    placeholder="vat"
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || loading}
          className={classes.submitButton}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  );
};

export default CreateContactForm;
