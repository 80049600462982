import axios from "axios";
import { BASE_URL_PRODUCT } from "../../config/config";
/* eslint-disable camelcase */

class AlertService {
  getconfirmation = (data) => {
    return new Promise((resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  };
}

const instance = new AlertService();

export default instance;
