import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, CircularProgress } from "@mui/material";
import { styles } from "../../../../../styles/actionButtons";
import withStyles from "@mui/styles/withStyles";
import { showMessage } from "../../../../../store/Revalu/notificationSlice";
import AlertDialog from "../../../../../common/AlertDialog/AlertDialog";
import { setConfirmation } from "../../../../../store/AlertDialog/alertDialogSlice";
import { deleteById } from "../../../../../store/OriginalEPDs/originalEPDSlice";
import { useDispatch } from "react-redux";
import { getOekobaudatList } from "../../../../../store/LCA/lcaSlice";

const ITEM_HEIGHT = 48;

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const {
    classes,
    rowData,
    onDownload = () => {},
    onViewDetails = () => {},
  } = props;

  const { id, blocked } = rowData;

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (type) => {
    onDownload(type);
    handleClose();
  };
  const handleViewDetails = () => {
    onViewDetails(rowData?.processInformation?.dataSetInformation?.UUID);
    handleClose();
  };

  const handleDelete = () => {
    setAlert(true);
    handleClose();
  };

  const handleCancel = () => {
    dispatch(setConfirmation(false));
    setAlert(false);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      setAlert(false);
      const res = await dispatch(
        deleteById(rowData?.processInformation?.dataSetInformation?.UUID),
      );
      if (Boolean(res?.deletedCount)) {
        dispatch(showMessage({ message: "Record deleted" }));
        await dispatch(getOekobaudatList());
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress size={20} color="primary" />;
  }

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        }}>
        {/* <MenuItem onClick={() => handleDownload("pdf")}>Download PDF</MenuItem>
        <MenuItem onClick={() => handleDownload("json")}>
          Download JSON
        </MenuItem> */}
        <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {alert ? (
        <AlertDialog
          title="Delete EPD"
          message="Are you sure want to delete this EPD?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default withStyles(styles)(ActionButtons);
