import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import { styles as dataTableStyles } from "../../../styles/dataTable";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  list,
  updateList,
  getExportProcessStatus,
  startExportProcess,
  searchData,
  qualityCheck,
  setSelected,
} from "../../../store/Manufactures/manufactureSlice";

// material ui
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";

// imports
import { withMultipleStyles } from "../../../styles/withMultipleStyles";
import ActionButtons from "./actionButtons";
import ExportEPDs from "./exportManufacturer";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import Search from "./Search";
import ViewDetails from "./viewDetails";
import QualityStatusForm from "./qualityStatusForm";
import ViewWarnings from "./viewWarnings";
import TableActionButton from "./tableActionButton";
import CreateManufacturerForm from "./createManufacture";
import { resetSelected } from "../../../store/Manufactures/manufactureSlice";
import ContactDetails from "./contactDetails";
import SupplierDetails from "./supplierDetails";
import ViewAllSuppliers from "./suppliers/all";
import { DialogHeader } from "../Common/dialogHeader";

const AllManufactureUsers = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const {
    data,
    loading,
    exportProcessStatus,
    exportProcessLoading,
    searchValue,
    selected: currentRecord,
  } = useSelector(({ manufactures }) => manufactures.slice);

  const { qualityValidationProcessLoading, qualityValidationProcessStatus } =
    useSelector(({ qualityValidation }) => qualityValidation.slice);

  const { totalElements } = data;
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [selectedIdToViewWarnings, setSelectedIdToViewWarnings] =
    useState(null);
  const [selectedIdToViewSuppliers, setSelectedIdToViewSuppliers] =
    useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);
  const [qualityStatusValue, setQualityStatusValue] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showLinkingForm, setShowLinkingForm] = useState(false);
  const [showSupplierLinkingForm, setShowSupplierLinkingForm] = useState(false);
  const [sortByName, setSortByName] = useState(false);

  const toggleSorting = () => {
    if (sortByName) {
      setSortByName(false);
    } else {
      setSortByName(true);
    }
  };

  const handleSelect = (id) => {
    dispatch(setSelected({ id }));
    setSelectedId(id);
    setShowAddModal(true);
  };
  const handleCloseEdit = () => {
    setSelectedId(null);
  };
  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
  };
  const handleContactLinking = (id) => {
    setSelectedIdToView(id);
    setSelectedId(id);
    dispatch(setSelected({ id }));
    setShowLinkingForm(true);
  };

  const handleSupplierLinking = (id) => {
    setShowSupplierLinkingForm(true);
    setSelectedIdToView(id);
    setSelectedId(id);
    dispatch(setSelected({ id }));
  };

  const handleViewWarnings = (id) => {
    setSelectedIdToViewWarnings(id);
  };
  const handleCloseDetails = () => {
    setSelectedIdToView(null);
    setShowLinkingForm(false);
    setShowSupplierLinkingForm(false);
  };
  const handleCloseWarnings = () => {
    setSelectedIdToViewWarnings(null);
    setSelectedIdToViewSuppliers(null);
  };

  const handleViewAllSuppliers = (id) => {
    setSelectedIdToViewSuppliers(id);
  };

  const handleQualityCheck = (id) => {
    dispatch(qualityCheck(id));
  };

  const handleQualityStatus = (id, status) => {
    const value = {
      id,
      status,
    };
    setQualityStatusValue(value);
    setShowModal((prev) => !prev);
  };

  const hideModal = () => {
    setShowModal(false);
    setShowAddModal(false);
    setShowHierarchyModal(false);
    dispatch(resetSelected());
  };

  const throwMsg = (ex) => {
    dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
  };
  const displayMsg = (res) => {
    if (res && typeof res === "string") {
      dispatch(showMessage({ message: res }));
    }
  };

  const handleStartQualityValidationProcess = async () => {
    // try {
    //   const res = await dispatch(startQualityValidationProcess())
    //   getQualityValidationProcessStatusFunc()
    //   displayMsg(res)
    // } catch (ex) {
    //   throwMsg(ex)
    // }
  };

  const updateTable = () => {
    // console.log({ sortByName })
    dispatch(list({ pageNo, pageSize, searchValue, sortByName }));
  };

  useEffect(() => {
    updateTable();
  }, [pageNo, pageSize, sortByName]);

  // If the search value changes set the page number to the first page
  useEffect(() => {
    setPageNo(0);
    updateTable();
  }, [searchValue]);

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    dispatch(updateList({ pageNo, pageSize, searchValue }));
  }, [qualityValidationProcessStatus]);

  // useEffect(() => {
  //   const getStatus = () => {
  //     dispatch(getExportProcessStatus())
  //   }
  //   dispatch(getExportProcessStatus())
  //   const interval = setInterval(() => {
  //     getStatus()
  //   }, 10000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  // useEffect(() => {
  //   getQualityValidationProcessStatusFunc()
  //   getCategoryEpdStatusFunc()
  //   const interval = setInterval(() => {
  //     getQualityValidationProcessStatusFunc()
  //     getCategoryEpdStatusFunc()
  //   }, 10000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  const headers = [
    {
      name: "Name",
      options: {
        sort: true,
      },
    },
    { name: "Platform", options: { sort: false } },
    { name: "Quality Status", options: { sort: false } },
    { name: "Blocked?", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              onViewWarnings={handleViewWarnings}
              onContactLinking={handleContactLinking}
              onSupplierLinking={handleSupplierLinking}
              onQualityCheck={handleQualityCheck}
              onQualityStatus={handleQualityStatus}
              onViewSuppliers={handleViewAllSuppliers}
              rowData={value}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m.name,
      m?.dataSource,
      m.qualityStatus ? m.qualityStatus : "Not Available",
      m["blocked"] ? "Yes" : "No",
      {
        id: m?.id,
        blocked: m.blocked,
        status: m.qualityStatus,
        featuredManufacturer: m?.featuredManufacturer,
      },
    ]);
  };

  const { classes } = props;

  const options = {
    sort: true,

    download: false,
    print: false,
    viewColumns: true,
    filter: false,
    checkbox: false,
    onColumnSortChange: () => toggleSorting(),
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    page: pageNo,
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },

    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search in name"
        />
      );
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Box display="flex" width="50%" gap="10px" flexWrap="wrap">
            {qualityValidationProcessStatus?.processing && (
              <div className={classes.notification}>
                Quality check process is in progress.
              </div>
            )}

            {exportProcessStatus?.processing && (
              <div className={classes.notification}>
                Extract process is in progress.
              </div>
            )}
          </Box>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={handleStartQualityValidationProcess}
              disabled={
                qualityValidationProcessLoading ||
                qualityValidationProcessStatus?.processing
              }
              loading={qualityValidationProcessLoading}
              title="Start Quality Check Process"
            />

            <ExportEPDs />
            <TableActionButton
              onClick={() => {
                setShowAddModal(true);
              }}
              title="Add Manufacturer"
            />
          </Box>
        </div>
      </div>

      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Manufacturers"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>

      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>{currentRecord ? "Update" : "Create"} Manufacturer</Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateManufacturerForm
            currentRecord={currentRecord}
            onClose={hideModal}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToView) && showSupplierLinkingForm && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Manufacturer Supplier linking"}
          />
          <SupplierDetails
            onClose={handleCloseDetails}
            setShowSupplierLinkingForm={setShowSupplierLinkingForm}
            id={selectedIdToView}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToView) &&
        showLinkingForm &&
        !showSupplierLinkingForm && (
          <Dialog
            open={Boolean(selectedIdToView)}
            maxWidth="lg"
            fullWidth
            onClose={handleCloseDetails}
            scroll="body">
            <DialogHeader
              onClose={handleCloseDetails}
              title={"Manufacturer Contact linking"}
            />
            <ContactDetails
              onClose={handleCloseDetails}
              setShowLinkingForm={setShowLinkingForm}
              id={selectedIdToView}
            />
          </Dialog>
        )}

      {Boolean(selectedIdToView) &&
        !showLinkingForm &&
        !showSupplierLinkingForm && (
          <Dialog
            open={Boolean(selectedIdToView)}
            maxWidth="lg"
            fullWidth
            onClose={handleCloseDetails}
            scroll="body">
            <DialogHeader
              onClose={handleCloseDetails}
              title={"Manufacturer Details"}
            />
            <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
          </Dialog>
        )}

      {Boolean(selectedIdToViewWarnings) && (
        <Dialog
          open={Boolean(selectedIdToViewWarnings)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseWarnings}
          scroll="body">
          <DialogHeader
            onClose={handleCloseWarnings}
            title={"Manufacturer Qual-ET warnings"}
          />
          <ViewWarnings
            onClose={handleCloseWarnings}
            id={selectedIdToViewWarnings}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToViewSuppliers) && (
        <Dialog
          open={Boolean(selectedIdToViewSuppliers)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseWarnings}
          scroll="body">
          <DialogHeader
            onClose={handleCloseWarnings}
            title={"Manufacturer Suppliers"}
          />
          <ViewAllSuppliers
            onClose={handleCloseWarnings}
            id={selectedIdToViewSuppliers}
          />
        </Dialog>
      )}

      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogHeader onClose={hideModal} title={"Update Quality Status"} />
          <QualityStatusForm
            onClose={hideModal}
            qualityStatusValue={qualityStatusValue}
          />
        </Dialog>
      )}
    </div>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(AllManufactureUsers);
