import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import AttributeDetails from "./attributeDetails";
import { styles } from "../../../styles/Dashboard/data";
import { CircularProgress, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getById } from "../../../store/Supplier/supplierSlice";

const ViewDetails = (props) => {
  const dispatch = useDispatch();
  const { id, classes } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getById(id));
          if (res) {
            setData(res);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);
  return (
    <div className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <AttributeDetails data={data} />
      )}
    </div>
  );
};

export default withStyles(styles)(ViewDetails);
