import React, { useState, useEffect } from "react";
import { styles } from "../../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { Button, Dialog, DialogTitle, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable_New from "../../../../common/DataTable/dataTable_new";
import DrawerSideBar from "../../../../common/Drawer/drawer";
import UploadCSV from "./uploadCSV";
import CreateForm from "./createForm";
import { getAllAttribute } from "../../../../store/Attributes/attributeSlice";
import { useSelector, useDispatch } from "react-redux";
import ActionButtons from "./actionButtons";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AllMetadata = (props) => {
  const [isCSVMode, setIsCSVMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [allowActionButtons, setAllowActionButtons] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { classes } = props;

  const dispatch = useDispatch();

  const viewAllAttribue = useSelector(
    ({ attributes }) => attributes.attributeAll.attributeData,
  );

  const getSelectedAttributeData = useSelector(
    ({ attributes }) => attributes.attributeAll.sourceUpdateData,
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      const isValid = await dispatch(getAllAttribute(data));
      // setLoading(false)
      return isValid;
    };

    fetchData();

    getSelectedAttributeData.length > 0 ? setShowModal(true) : "";
  }, [dispatch, getSelectedAttributeData]);

  const headings = ["ID", "MetaData"];
  const rowsData = viewAllAttribue.map((item) => {
    return [item.id, item?.metaData];
  });

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const hideModal = () => {
    setShowModal(false);
    setCurrentRecord(null);
  };

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">All MetaData</h1>
      <div className={classes.headerContent}>
        <Button
          style={{ marginRight: 13 }}
          className={isCSVMode ? "lightButton" : "activeButton"}
          onClick={() => setIsCSVMode(false)}>
          Form
        </Button>
        {/* <Button
          className={isCSVMode ? "activeButton" : "lightButton"}
          onClick={() => setIsCSVMode(true)}>
          CSV File
        </Button> */}
        {/* <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            // onClick={() => setIsCreateMode(!isCreateMode)}
            onClick={toggleModal}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            {getSelectedAttributeData.length > 0
              ? "Update Here"
              : "Add new Attribute"}
          </Button>
        </div> */}
      </div>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
        ActionButtons={ActionButtons}
      />
      {/* {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          {isCSVMode ? (
            <UploadCSV />
          ) : (
            <CreateForm
              getSelectedAttributeData={getSelectedAttributeData}
            />
          )}
        </DrawerSideBar>
      )} */}
      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {getSelectedAttributeData.length > 0 ? "Update" : "Create"}{" "}
                Attribute
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateForm
            currentRecord={getSelectedAttributeData}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(AllMetadata);
