import { createSlice } from "@reduxjs/toolkit";
import { DashboardService } from "../../services/dashboardService";
import { showMessage } from "../Revalu/notificationSlice";
import { Routes } from "../../common/Constants/routes";

export const getAllCounts = (data) => async (dispatch) => {
  return DashboardService.getAllCounts(data)
    .then((res) => {
      dispatch(dashboardSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};
export const getAllUserCounts = (data) => async (dispatch) => {
  return DashboardService.getAllUserCounts(data)
    .then((res) => {
      dispatch(dashboardUserSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  dashboardData: [],
  dashboardUserData: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true;
      state.product = action.payload;
    },
    dashboardSuccess: (state, action) => {
      state.success = true;
      state.dashboardData = action.payload ? action.payload : [];
    },
    dashboardUserSuccess: (state, action) => {
      state.success = true;
      state.dashboardUserData = action.payload ? action.payload : [];
    },
  },
});

export const { productSuccess, dashboardSuccess, dashboardUserSuccess } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
