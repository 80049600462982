import { createSlice } from "@reduxjs/toolkit";
import history from "../../utils/history";
import _ from "lodash";
import AuthService from "./../../services/authService/authService";
import { showMessage } from "../../store/Revalu/notificationSlice";

export const setUserData = (user) => async (dispatch, getState) => {
  history.location.state = {
    redirectUrl: user.redirectUrl, // redirect to url after login
  };

  //TODO: create user settings store and set default settings
  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const logoutUser = (history) => async (dispatch) => {
  history.push({
    pathname: "/login",
  });

  AuthService.logout();
  dispatch(
    showMessage({
      message: "User Logged out successfuly",
    }),
  );

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  AuthService.updateUserData(user)
    .then(() => {})
    .catch((error) => {});
};

const initialState = {
  role: [],
  data: {},
  authenticatedUser: {},
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => (state.data = action.payload),
    setAuthenticatedUser: (state, action) =>
      state.authenticatedUser === action.payload,
    userLoggedOut: (state, action) => initialState,
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
