import axios from "axios";
import { BASE_URL_EPD } from "../../config/config";
/* eslint-disable camelcase */

class AttributeService {
  getAllSourceData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_EPD + "sourceAttribute/source-attribute/all", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getAllSemanticGroupData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_EPD + "epd-semantic-group/semantic-group/all", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getAllAttributeData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_EPD + "epd-attribute/attribute/all", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  addNewSource = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "sourceAttribute/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  addNewSemanticGroup = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "epd-semantic-group/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  addNewAttribute = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "epd-attribute/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateDataSource = (value, id) => {
    const data = {
      id: id,
      sourceUrl: value.sourceUrl,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_EPD + "sourceAttribute/update" + `?id=${id}`, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateDataSemanticGroup = (value, semanticGroup_OldName) => {
    const data = {
      semanticGroupName: value.semanticGroupName,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_EPD +
            "epd-semantic-group/update" +
            `?semanticGroupName=${semanticGroup_OldName}`,
          data,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateDataAttribute = (value) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_EPD +
            "epd-attribute/update" +
            `?attributeName=${value.attributeName}`,
          value,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: error,
          };
          reject([errors]);
        });
    });
  };

  updateAttributeSelectedData = (data) => {
    return new Promise((resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  };

  deleteSourceData = (data) => {
    const reqData = {
      params: {
        sourceUrl: data[1],
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "sourceAttribute/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteSemanticGroupData = (data) => {
    const reqData = {
      params: {
        semanticGroupName: data[1],
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "epd-semantic-group/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteAttributeData = (name) => {
    const reqData = {
      params: {
        attributeName: name,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_EPD + "epd-attribute/delete", reqData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  csvUpload = (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_EPD + "epd-attribute/csvUpload", formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          const errors = {
            message: response.data,
          };
          reject([errors]);
        });
    });
  };
}
const instance = new AttributeService();

export default instance;
