import React, { useState, useEffect } from "react";
import { styles } from "../../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { Button, Dialog, DialogTitle, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable_New from "../../../../common/DataTable/dataTable_new";
import DrawerSideBar from "../../../../common/Drawer/drawer";
import UploadCSV from "./uploadCSV";
import CreateForm from "./createForm";
import {
  getAllLevel2,
  updateSelectedData,
} from "../../../../store/CategoryHierarchy/categoryHierarchySlice";
import { useSelector, useDispatch } from "react-redux";
import ActionButtons from "./actionButtons";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const AllLevel2 = (props) => {
  const [isCSVMode, setIsCSVMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [allowActionButtons, setAllowActionButtons] = useState(true);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();

  const viewAllLevel2 = useSelector(
    ({ categoryHierarchy }) =>
      categoryHierarchy.categoryHierarchyAll.level2Data,
  );

  const getSelectedLevel1Data = useSelector(
    ({ categoryHierarchy }) =>
      categoryHierarchy.categoryHierarchyAll.updateData,
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      const isValid = await dispatch(getAllLevel2(data));
      // setLoading(false)
      return isValid;
    };

    fetchData();

    getSelectedLevel1Data.length > 0 ? setShowModal(true) : "";
  }, [dispatch, getSelectedLevel1Data]);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const hideModal = () => {
    setShowModal(false);
    setCurrentRecord(null);
    dispatch(updateSelectedData(""));
  };

  const headings = ["ID", "Name"];
  const rowsData = viewAllLevel2?.map((item) => {
    return [item.id, item.subCategoryName];
  });

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">Level-2 Category</h1>
      <div className={classes.headerContent}>
        <Button
          style={{ marginRight: 13 }}
          className={isCSVMode ? "lightButton" : "activeButton"}
          onClick={() => setIsCSVMode(false)}>
          Form
        </Button>
        {/* <Button
          className={isCSVMode ? "activeButton" : "lightButton"}
          onClick={() => setIsCSVMode(true)}>
          CSV File
        </Button> */}
        <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={toggleModal}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            {getSelectedLevel1Data.length > 0 ? "Update Here" : "Add new"}
          </Button>
        </div>
      </div>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
        ActionButtons={ActionButtons}
      />
      {/* {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          {isCSVMode ? (
            <UploadCSV />
          ) : (
            <CreateForm getSelectedLevel1Data={getSelectedLevel1Data} />
          )}
        </DrawerSideBar>
      )} */}
      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {getSelectedLevel1Data.length > 0 ? "Update" : "Create"} Level-2
                Category
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateForm
            currentRecord={getSelectedLevel1Data}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(AllLevel2);
