import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { createReferenceProjectDocument } from "../../../../store/MappedEDPs/mappedEPDSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { useState } from "react";
import { handleUploadFile } from "../../../../utils/fileUpload";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const UploadReferenceDocument = ({ datasource, onClose = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    name: "",
    image: "",
    location: "",
    typology: "",
    pdfDocument: "",
    dataSourceId: datasource,
  };

  const addNewSchema = yup.object().shape({
    name: yup.string(),
    image: yup.string(),
    location: yup.string(),
    typology: yup.string(),
    pdfDocument: yup.string().required("Required"),
    dataSourceId: yup.string(),
  });

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onResponse = (res) => {
    if (Boolean(res)) {
      dispatch(
        showMessage({
          message: `The document has been uploaded}`,
        }),
      );
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(showMessage({ message: "already exists", variant: "error" }));
      }
    }
  };

  const onSubmit = async (model) => {
    setLoading(true);
    const res = await dispatch(createReferenceProjectDocument(model));
    onResponse(res);
    setLoading(false);
  };

  const handleUpload = (e, field, publicBucket) => {
    if (e.target.files.length) {
      setLoading(true);
      handleUploadFile(e, publicBucket)
        .then((res) => {
          setValue(field, res.data);
        })
        .catch((err) => {
          alert(err?.message || "Error uploading file");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Image</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "25px",
                }}>
                <img src={getValues("image")} alt="" /> <br />
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "image", true)}
                />
              </div>
            </div>
          </Grid>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Name</label>
            <Controller
              name="name"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="reference-project-input"
                  {...field}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  placeholder="Name"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Location</label>
            <Controller
              name="location"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="reference-project-input"
                  {...field}
                  error={!!errors.location}
                  helperText={errors?.location?.message}
                  variant="outlined"
                  placeholder="Location"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Typology</label>
            <Controller
              name="typology"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="reference-project-input"
                  {...field}
                  error={!!errors.typology}
                  helperText={errors?.typology?.message}
                  variant="outlined"
                  placeholder="Typology"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>

          <Grid item xs={12} md={12}>
            <div
              className={classes.formWrapper}
              style={{
                marginTop: "20px",
              }}>
              <label className={classes.label}>PDF Document</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}>
                {getValues("pdfDocument") && "The Document is uploaded"}
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "pdfDocument", false)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          // disabled={!isValid || loading}
          className={classes.submitButton}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  );
};

export default UploadReferenceDocument;
