import { createSlice } from "@reduxjs/toolkit";
import { LCAService } from "../../services/lcaService";
import { ObjectTypeService } from "../../services/objectTypeService";

export const upload = (formData) => async (dispatch) => {
  dispatch(setUploadLoading(true));
  return LCAService.upload(formData)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setUploadLoading(false));
    });
};
export const download = (data) => async (dispatch) => {
  return LCAService.download(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return LCAService.list(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getOekobaudatList = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return LCAService.oekobaudatlist(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const create = (data) => async (dispatch) => {
  dispatch(setRecordLoading(true));
  return ObjectTypeService.create(data)
    .then(async (res) => {
      setLoading(true);
      ObjectTypeService.list(data)
        .then((res) => {
          dispatch(setData(res));
        })
        .finally(() => {
          setLoading(false);
        });
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setRecordLoading(false));
    });
};
export const update = (data, id) => async (dispatch) => {
  dispatch(setRecordLoading(true));
  return ObjectTypeService.update(data, id)
    .then((res) => {
      const { id } = res;
      if (Boolean(id)) {
        dispatch(modifyRecordInState(res));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setRecordLoading(false));
    });
};
export const deleteByName = (name) => async (dispatch) => {
  dispatch(setLoading(true));
  return ObjectTypeService.deleteByName(name)
    .then(async (res) => {
      dispatch(removeRecordFromStateByName(name));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};
export const getImportProcessStatus = () => async (dispatch) => {
  return LCAService.getImportProcessStatus()
    .then(async (res) => {
      dispatch(setImportProcessStatus(res));
    })
    .catch((errors) => {
      dispatch(setImportProcessStatus(null));
    });
};
export const importLCA = (data) => async (dispatch) => {
  dispatch(setImportLoading(true));
  return LCAService.import(data)
    .then(async (res) => {
      await getImportProcessStatus();
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setImportLoading(false));
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  recordLoading: false,
  importLoading: false,
  uploadLoading: false,
  searchValue: "",
  importProcessStatus: null,
};

const lcaSlice = createSlice({
  name: "lca/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setImportProcessStatus: (state, action) => {
      state.importProcessStatus = action.payload;
    },
    setRecordLoading: (state, action) => {
      state.recordLoading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    // searchData: (state, action) => {
    //   let { search } = action.payload
    //   if (!search) {
    //     state.data = state.originalData
    //     return
    //   }
    //   search = search.toLowerCase()
    //   const searchData = state.originalData.content.filter((f) => {
    //     return (
    //       f.processInformation?.dataSetInformation?.UUID.toLowerCase().indexOf(
    //         search,
    //       ) > -1 ||
    //       f.processInformation?.dataSetInformation?.name?.baseName[0]?.value
    //         .toLowerCase()
    //         .indexOf(search) > -1
    //     )
    //   })
    //   state.data = {
    //     content: searchData,
    //     totalPages: 1,
    //     totalElements: searchData.length,
    //   }
    // },
    searchData: (state, action) => {
      const { search } = action.payload;
      state.searchValue = search;
    },
    setImportLoading: (state, action) => {
      state.importLoading = action.payload;
    },
    setUploadLoading: (state, action) => {
      state.uploadLoading = action.payload;
    },
    modifyRecordInState: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.data[index] = action.payload;
      }
    },
    removeRecordFromStateByName: (state, action) => {
      const name = action.payload;
      const indexToRemove = state.data.findIndex((f) => f.name === name);
      if (indexToRemove > -1) {
        state.data.splice(indexToRemove, 1);
      }
    },
    resetState: (state) => {
      state.recordLoading = false;
    },
  },
});

export const {
  setLoading,
  setRecordLoading,
  setData,
  modifyRecordInState,
  removeRecordFromStateByName,
  resetState,
  setImportLoading,
  setUploadLoading,
  searchData,
  setImportProcessStatus,
} = lcaSlice.actions;

export default lcaSlice.reducer;
