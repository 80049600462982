import { createSlice } from "@reduxjs/toolkit";
import { QualityValidationService } from "../../services/qualityValidationService";

export const startQualityValidationProcess = () => async (dispatch) => {
  dispatch(setQualityValidationProcessLoading(true));
  return QualityValidationService.startQualityValidationProcess()
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setQualityValidationProcessLoading(false));
    });
};

export const getQualityValidationProcessStatus = () => async (dispatch) => {
  return QualityValidationService.getQualityValidationProcessStatus()
    .then((res) => {
      dispatch(setQualityValidationProcessStatus(res));
    })
    .catch((errors) => {
      dispatch(setQualityValidationProcessStatus(null));
    });
};

export const getWarningsById = (id) => async (dispatch) => {
  return QualityValidationService.getWarningsById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getErrorssById = (id) => async (dispatch) => {
  return QualityValidationService.getErrorssById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

const initialState = {
  loading: false,
  qualityValidationProcessStatus: null,
  qualityValidationProcessLoading: false,
};

const qualityValidationSlice = createSlice({
  name: "qualityValidation/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQualityValidationProcessLoading: (state, action) => {
      state.qualityValidationProcessLoading = action.payload;
    },
    setQualityValidationProcessStatus: (state, action) => {
      state.qualityValidationProcessStatus = action.payload;
    },
  },
});

export const {
  setLoading,
  setQualityValidationProcessStatus,
  setQualityValidationProcessLoading,
} = qualityValidationSlice.actions;

export default qualityValidationSlice.reducer;
