import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  updateSupportPageTiles,
  submitSupportUpdate,
} from "../../../../store/Support/supportSlice";
import AlertDialog from "../../../../common/AlertDialog/AlertDialog";

function CustomerService(props) {
  const [data, setData] = useState();
  const [classOne, setClassOne] = useState();
  const [classTwo, setClassTwo] = useState();
  const [alert, setAlert] = useState(false);
  const [selectValue, setSelectValue] = useState();

  const dispatch = useDispatch();

  const { supportDetail, alertConfirmation } = props;

  const deleteHandler = async () => {
    if (alertConfirmation == true) {
      const allArr = supportDetail[0].tiles[0].topics;
      const newAllArr = [...allArr];

      const index = allArr.findIndex(
        (item) =>
          item.title === selectValue?.title &&
          item.content === selectValue?.content,
      );

      newAllArr.splice(index, 1);

      const data = {
        newAllArr,
        supportDetail,
      };
      dispatch(submitSupportUpdate(data));
      setAlert(false);
    }
  };

  const editHandler = async (data) => {
    const isValid = await dispatch(updateSupportPageTiles(data));
    return isValid;
  };

  const showAlertDialogDelete = (value, data) => {
    setAlert(value);
    setSelectValue(data);
  };

  const { servicesData, classes } = props;
  return (
    <div className={classes.services_accordion}>
      {servicesData?.tiles
        ? servicesData?.tiles[0]?.topics.map((value) => (
            <Accordion
              key={value.id}
              onClick={(e) => {
                setClassOne(e.target.classList[1]);
                setClassTwo(e.target.classList[2]);
              }}>
              <AccordionSummary
                expandIcon={
                  data === value.title &&
                  classOne !== "Mui-expanded" &&
                  classTwo !== "Mui-expanded" ? (
                    <MinimizeIcon />
                  ) : (
                    <AddIcon />
                  )
                }
                onClick={() => setData(value.title)}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>{value.title}</Typography>
                <div className="actionButtons">
                  <button onClick={() => editHandler(value)}>
                    <EditIcon />
                  </button>
                  <button onClick={() => showAlertDialogDelete(true, value)}>
                    <DeleteIcon />
                  </button>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{value.content}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        : ""}
      {alert === true ? (
        <AlertDialog
          title="Delete Tile"
          message="Are you sure want to delete this account?"
          onLoad={deleteHandler()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default CustomerService;
