export const styles = (theme) => ({
  mainrapper: {
    background: theme.palette.primary.bg,
    "& tr": {
      background: theme.dataTable.background + " !important",
      borderBottom: "1px solid " + theme.dataTable.border,
      "& td": {
        padding: "13px 15px",
        overflow: "hidden",
        "& svg": {
          fontSize: 17,
          curosr: "pointer",
        },
      },
      "& th": {
        color: theme.dataTable.color,
        padding: "13px 16px",
      },
      "&:hover": {
        background: theme.dataTable.hover + " !important",
      },
    },
    "& .MuiTableHead-root th": {
      fontWeight: "600",
      "&:first-child": {
        textAlign: "left",
      },
    },
    "& .MuiTableCell-body": {
      "&:first-child": {
        textAlign: "left",
      },
    },

    MuiTableBody: {
      root: {
        backgroundColor: theme.dataTable.background,
      },
    },
  },
});
