export const styles = (theme) => ({
  mainWrapper: {
    background: theme.palette.primary.loginBg,
  },
  wrapper: {
    margin: "auto",
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "0px 32px",
    "& h1": {
      margin: 0,
      color: theme.palette.primary.boldFont,
    },
    "& p": {
      fontSize: 12,
      color: theme.palette.primary.fontColor,
      margin: "0px",
      marginBottom: 11,
    },
    "& button": {
      width: "100%",
      padding: 8,
      marginBottom: 7,
      color: "white",
      borderRadius: "5px !important",
    },
    "& .login": {
      background: "#c23939",
      width: 220,
      padding: 8,
      marginBottom: 7,
      color: "white",
      borderRadius: "5px !important",
      textDecoration: "none",
      textAlign: "center",
    },
  },
  formWrapper: {
    marginBottom: 28,
    "& input": {
      border: "1px solid #aeaeae",
      borderRadius: 13,
      padding: "7px 18px",
      background: theme.palette.primary.textFieldBg,
    },
    "& fieldset": {
      borderRadius: 13,
    },
  },
  conditionsWrapper: {
    "& .terms": {
      "& .main": {
        borderBottom: "1px solid #e7e7e7",
        paddingBottom: 11,
        fontWeight: 600,
        color: theme.palette.primary.boldFont,
      },
      "& p": {
        display: "flex",
        fontSize: 12,
        alignItems: "center",
        color: theme.palette.primary.fontColor,
        "& svg": {
          fontSize: 14,
          marginRight: 7,
          color: theme.palette.primary.disable,
        },
        "& a": {
          color: theme.palette.primary.links,
          marginLeft: 4,
        },
        "& span": {
          color: theme.palette.primary.confirm,
          marginLeft: 11,
        },
      },
    },
  },
});
