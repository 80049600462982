import { createSlice } from "@reduxjs/toolkit";
import { SupportService } from "../../services/supportService";
import { showMessage } from "../Revalu/notificationSlice";
import { Routes } from "../../common/Constants/routes";

export const getSupportPageData = (data) => async (dispatch) => {
  return SupportService.getAllSupportData(data)
    .then((res) => {
      dispatch(supportSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitSupport = (data) => async (dispatch) => {
  return SupportService.createSupport(data)
    .then((user) => {
      dispatch(supportSuccess());
      history.push(Routes.user.support);
      return;
    })
    .catch((errors) => {
      return dispatch(supportError(errors));
    });
};

export const updateSupportPageTiles = (data) => async (dispatch) => {
  return SupportService.updateSupportData(data)
    .then((res) => {
      dispatch(supportUpdateSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const updateSupportPageHeadline = (data) => async (dispatch) => {
  return SupportService.updateSupportData(data)
    .then((res) => {
      dispatch(supportUpdateHeadlineSuccess(res.headline));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const submitSupportUpdate = (data, history) => async (dispatch) => {
  return SupportService.updateSupport(data)
    .then((user) => {
      dispatch(supportSuccess());
      dispatch(showMessage({ message: "Tiles Updated successfuly" }));
      history.push(Routes.user.support);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return dispatch(supportError(errors));
    });
};

export const submitAddNew = (data, history) => async (dispatch) => {
  return SupportService.addNewArray(data)
    .then((user) => {
      dispatch(supportSuccess());
      dispatch(showMessage({ message: "New Tile Added successfuly" }));
      history.push(Routes.user.support);
      window.location.reload();
      return;
    })
    .catch((errors) => {
      return dispatch(supportError(errors));
    });
};

export const submitSupportUpdateHeadline =
  (data, history) => async (dispatch) => {
    return SupportService.updateSupportHeadline(data)
      .then((user) => {
        dispatch(showMessage({ message: "Page updated successfuly" }));
        return true;
      })
      .catch((errors) => {
        return dispatch(supportError(errors));
      });
  };

export const deleteSupportPagedata = (id) => async (dispatch) => {
  return SupportService.deleteSupportData(id)
    .then((res) => {
      dispatch(supportUpdateSuccess(res));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

export const addNewTile = (data) => async (dispatch) => {
  return dispatch(addNewSuccess(data));
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  supportData: [],
  supportUpdateData: [],
  supportUpdateHeadlineData: [],
  addNew: "",
  orderItems: [],
};

const supportSlice = createSlice({
  name: "support/listing",
  initialState,
  reducers: {
    supportSuccess: (state, action) => {
      state.success = true;
      state.supportData = action.payload ? [action.payload] : [];
    },
    supportUpdateSuccess: (state, action) => {
      state.success = true;
      state.supportUpdateData = action.payload ? [action.payload] : [];
    },
    supportUpdateHeadlineSuccess: (state, action) => {
      state.success = true;
      state.supportUpdateHeadlineData = action.payload ? [action.payload] : [];
    },
    addNewSuccess: (state, action) => {
      state.success = true;
      state.addNew = action.payload ? action.payload : "";
    },
    supportError: (state, action) => {
      state.success = false;
      state.errors = action.payload ? action.payload : [];
    },
  },
});

export const {
  supportSuccess,
  supportUpdateSuccess,
  supportUpdateHeadlineSuccess,
  addNewSuccess,
  supportError,
} = supportSlice.actions;

export default supportSlice.reducer;
