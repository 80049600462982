import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  getAllSource,
  getAllSemanticGroup,
  submitAddAttribute,
  updateAttribute,
} from "../../../../store/Attributes/attributeSlice";
import { list } from "../../../../store/ObjectType/objectTypeSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
    marginTop: 10,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const ObjectTypeForm = ({ currentRecord, onClose = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice);
  const [sourceUrlValue, setSourceUrlValue] = useState("");
  const [semanticValue, setSemanticValue] = useState("");
  const [objTypeValue, setObjTypeValue] = useState("");
  const [metadataValue, setMetadataValue] = useState("");
  const [attributeNameValue, setAttributeNameValue] = useState("");

  const id = currentRecord.length > 0 ? currentRecord[0][0] : "";
  const defaultValues = {
    name: currentRecord?.name || "",
  };

  const currentData = currentRecord[0];

  const viewAllSource = useSelector(
    ({ attributes }) => attributes.attributeAll.attributeSourceData,
  );

  const viewAllSemanticGroup = useSelector(
    ({ attributes }) => attributes.attributeAll.attributeSemanticData,
  );

  const viewAllObjType = useSelector(({ objectType }) => objectType.slice.data);

  // const schema = yup.object().shape({
  //   name: yup.string().required("Required"),
  // })

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(),
  });
  const { isValid, errors } = formState;

  const onResponse = (res) => {
    const { id, status, data } = res;
    if (id) {
      dispatch(showMessage({ message: "Saved successfully" }));
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        !data ||
        (data &&
          data["database exception"]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf("duplicate") > -1)
      ) {
        dispatch(
          showMessage({
            message: "Attribute already exists",
            variant: "error",
          }),
        );
      }
    }
  };

  const onSubmit = async (model) => {
    const data = {
      attributeName: attributeNameValue
        ? attributeNameValue
        : currentData[1]
          ? currentData[1]
          : "",
      metaData: metadataValue
        ? metadataValue
        : currentData[4]
          ? currentData[4]
          : "",
      attributeSourceId: sourceUrlValue.id,
      epdSemanticGroupId: semanticValue.id,
      objectTypeId: objTypeValue.id,
    };

    if (id) {
      await dispatch(updateAttribute(data, history));
    } else {
      await dispatch(submitAddAttribute(data, history));
    }
  };

  useEffect(() => {
    const fetchSourceData = async () => {
      const isValid = await dispatch(getAllSource());
      return isValid;
    };
    const fetchSemanticGroupData = async () => {
      const isValid = await dispatch(getAllSemanticGroup());
      return isValid;
    };
    const fetchObjTypeData = async () => {
      const isValid = await dispatch(list());
      return isValid;
    };

    fetchSourceData();
    fetchSemanticGroupData();
    fetchObjTypeData();
  }, [dispatch]);

  const onChangeSource = (event, child) => {
    setSourceUrlValue(event.target.value);
  };

  const onChangeSemantic = (event, child) => {
    setSemanticValue(event.target.value);
  };

  const onChangeObjType = (event, child) => {
    setObjTypeValue(event.target.value);
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Name*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Name"
                    onChange={(e) => setAttributeNameValue(e.target.value)}
                    value={
                      attributeNameValue
                        ? attributeNameValue
                        : currentRecord.length > 0
                          ? currentData[1]
                          : ""
                    }
                    disabled={currentRecord.length > 0 ? true : false}
                    required
                  />
                )}
              />
              <br />
              <label className={classes.label}>Source ID*</label>
              <Controller
                name="Source ID"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    value={sourceUrlValue?.sourceUrl}
                    label="Source ID"
                    required
                    onChange={onChangeSource}>
                    {viewAllSource
                      ? viewAllSource.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.sourceUrl}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                )}
              />
              <br />
              <label className={classes.label}>Semantic Group*</label>
              <Controller
                name="Semantic Group"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    value={semanticValue?.semanticGroupName}
                    label="Semantic Group"
                    required
                    onChange={onChangeSemantic}>
                    {viewAllSemanticGroup
                      ? viewAllSemanticGroup.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.semanticGroupName}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                )}
              />
              <br />
              <label className={classes.label}>MetaData*</label>
              <Controller
                name="metaData"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="MetaData"
                    onChange={(e) => setMetadataValue(e.target.value)}
                    value={
                      metadataValue
                        ? metadataValue
                        : currentRecord.length > 0
                          ? currentData[4]
                          : ""
                    }
                    required
                  />
                )}
              />
              <br />
              <label className={classes.label}>Object Type*</label>
              <Controller
                name="Object Type"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    value={objTypeValue?.name}
                    label="Object Type"
                    required
                    onChange={onChangeObjType}>
                    {viewAllObjType
                      ? viewAllObjType.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || recordLoading}
          className={classes.submitButton}>
          {recordLoading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  );
};

export default ObjectTypeForm;
