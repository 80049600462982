import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import { getAllBuyer } from "../../../store/Buyer/buyerSlice";
import { useSelector, useDispatch } from "react-redux";
import ActionButtons from "./actionButtons";

const AllBuyer = (props) => {
  const [allowActionButtons, setAllowActionButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();

  const viewAllBuyer = useSelector(({ buyer }) => buyer.buyerAll.buyerData);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = {};
      await dispatch(getAllBuyer(data));
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const headings = ["ID", "Buyer Name", "UserName", "User Type", "Email"];
  const rowsData = viewAllBuyer.map((item) => {
    return [item.id, item.name, item.username, item.userType, item.email];
  });

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">All Buyers</h1>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
        ActionButtons={ActionButtons}
      />
      {isLoading && <span>Loading Buyers</span>}
    </div>
  );
};

export default withStyles(styles)(AllBuyer);
