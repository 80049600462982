import React, { useEffect, useState } from "react";
import { styles } from "../../styles/login";
import withStyles from "@mui/styles/withStyles";
import { TextField, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "./../../store/Admin/loginSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { Routes } from "../../common/Constants/routes";
import * as yup from "yup";
import _ from "lodash";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup.string().required("Please enter your password."),
});

const defaultValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { classes } = props;

  const login = useSelector(({ user }) => user.userLogin);

  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: "manual",
        message: error.message,
      });
    });
  }, [login.errors, setError]);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const onSubmit = (model) => {
    dispatch(submitLogin(model, history));
  };

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.wrapper}>
        <h1>Log in</h1>
        <p>Please sign-in and lets start</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>
              Email <span>*</span>
            </label>
            <Controller
              name="email"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  id="outlined-email"
                  placeholder="test@revalu.io"
                  required
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>
              Password <span>*</span>
            </label>
            <Controller
              name="password"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  placeholder="Password"
                  type={passwordVisible ? "text" : "password"}
                  autoComplete=""
                  required
                  id="password-input"
                />
              )}
            />
          </div>
          <div className={classes.conditionsWrapper}>
            <div className="terms">
              <p className="main">
                <CheckCircleIcon /> I agree to all terms and conditions
              </p>
            </div>
          </div>
          <Button
            id="login-submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            className={classes.submitButton}
            variant="contained"
            color="primary">
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
