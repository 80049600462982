import { createSlice } from "@reduxjs/toolkit";
import { SellerService } from "../../services/sellerService";
import { showMessage } from "../../store/Revalu/notificationSlice";
import { Routes } from "../../common/Constants/routes";

export const getAllSeller = (data) => async (dispatch) => {
  return SellerService.getAllSellerData(data)
    .then((products) => {
      dispatch(sellerSuccess(products));
      return true;
    })
    .catch((errors) => {
      return true;
    });
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  sellerData: [],
  orderItems: [],
};

const sellerSlice = createSlice({
  name: "seller/listing",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true;
      state.product = action.payload;
    },
    sellerSuccess: (state, action) => {
      state.success = true;
      state.sellerData = action.payload ? [...action.payload] : [];
    },
    orderItemSuccess: (state, action) => {
      state.success = true;
      state.orderItems = action.payload ? [...action.payload] : [];
    },
  },
});

export const { productSuccess, sellerSuccess, orderItemSuccess } =
  sellerSlice.actions;

export default sellerSlice.reducer;
