import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { getWarningsById } from "../../../../store/QualityValidation/qualityValidationSlice";
import { styles } from "../../../../styles/Dashboard/data";
import { CircularProgress, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import WarningItem from "../../../../common/Helper/warningItem";

const ViewWarnings = (props) => {
  const dispatch = useDispatch();
  const { id, classes } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [discards, setDiscards] = useState([]);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getWarningsById(id));
          if (res && Boolean(res.dataSourceId)) {
            if (res?.qualityCheckWarnings) {
              setWarnings(
                res?.qualityCheckWarnings.filter(
                  (w) => w.type === "warning" && w?.discarded !== true,
                ),
              );
              setErrors(
                res?.qualityCheckWarnings.filter(
                  (w) => w.type === "error" && w?.discarded !== true,
                ),
              );
              setDiscards(
                res?.qualityCheckWarnings.filter((w) => w.discarded === true),
              );
            }
            setData(res);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);
  return (
    <div
      className={classes.pageWrapper}
      style={{ marginTop: -35, paddingTop: 0 }}>
      <section style={{ borderBottom: "1px dotted" }}>
        <h1>Warnings</h1>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        ) : !warnings.length ? (
          <p>No warnings</p>
        ) : (
          <Fragment>
            {Boolean(warnings.length) &&
              warnings.map((m) => <WarningItem data={m} key={m.code} />)}
          </Fragment>
        )}
      </section>
      <section style={{ borderBottom: "1px dotted" }}>
        <h1>Errors</h1>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        ) : !errors.length ? (
          <p>No errors</p>
        ) : (
          <Fragment>
            {errors.length &&
              errors.map((m) => <WarningItem data={m} key={m.code} />)}
          </Fragment>
        )}
      </section>
      {!discards.length ? (
        <></>
      ) : (
        <section style={{ borderBottom: "1px dotted" }}>
          <h1>Discarded Results</h1>
          <Fragment>
            {discards.length &&
              discards.map((m) => <WarningItem data={m} key={m.code} />)}
          </Fragment>
        </section>
      )}
    </div>
  );
};

export default withStyles(styles)(ViewWarnings);
