import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  getAllElements,
  getAllLevel1,
  getAllLevel2,
  submitRelationship,
} from "../../../../store/CategoryHierarchy/categoryHierarchySlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import PasswordField from "../../../common/Fields/PasswordField"
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    justifyContent: "center",
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  placeholder: {
    color: "#8e8e8e",
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const RelationshipForm = ({ onClose = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [elementsValue, setElementsValue] = useState("");
  const [broaderCategoriesValue, setBroaderCategoriesValue] = useState("");
  const [subCategoriesValue, setSubCategoriesValue] = useState("");
  const [getElementsID, setGetElementsID] = useState("");
  const [getBroaderCategoriesID, setGetBroaderCategoriesID] = useState("");
  const [getSubCategoriesID, setGetSubCategoriesID] = useState("");
  const [viewAllData, setViewAllData] = useState("");
  const history = useHistory();
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice);
  const { elementsData, level1Data, level2Data } = useSelector(
    ({ categoryHierarchy }) => categoryHierarchy.categoryHierarchyAll,
  );
  // const id = qualityStatusValue?.id
  const defaultValues = {
    name: viewAllData?.elementName || "",
  };

  useEffect(async () => {
    await dispatch(getAllElements());
    await dispatch(getAllLevel1());
    await dispatch(getAllLevel2());
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  const { control, formState, handleSubmit, setError, reset } = useForm({
    // mode: "onChange",
    // defaultValues,
    // resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  const onResponse = (res) => {
    if (res == false) {
      dispatch(showMessage({ message: "Error", variant: "error" }));
      onClose();
    }
    onClose();
    setElementsValue("");
    setBroaderCategoriesValue("");
    setSubCategoriesValue("");
  };

  const onSubmit = async (model) => {
    const data = {
      elementId: getElementsID,
      broaderCategoryId: getBroaderCategoriesID,
      subCategoryId: getSubCategoriesID,
    };
    const responce = await dispatch(submitRelationship(data, history));
    onResponse(responce);
  };

  const onChangeElements = (event) => {
    setElementsValue(event.target.value);
  };

  const onChangeBroaderCategories = (event) => {
    setBroaderCategoriesValue(event.target.value);
  };
  const onChangeSubCategories = (event) => {
    setSubCategoriesValue(event.target.value);
  };
  const selectedElementValue = (data) => {
    setGetElementsID(data.id);
  };

  const selectedBroaderCategoriesValue = (data) => {
    setGetBroaderCategoriesID(data.id);
  };

  const selectedSubCategoriesValue = (data) => {
    setGetSubCategoriesID(data.id);
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Elements*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      elementsValue
                        ? () => elementsValue
                        : () => (
                            <placeholder style={{ color: "#8e8e8e" }}>
                            Select Element
                          </placeholder>
                          )
                    }
                    value={elementsValue ? elementsValue : ""}
                    label="Elements"
                    onChange={onChangeElements}>
                    {elementsData ? (
                      elementsData?.map((item) => (
                        <MenuItem
                          value={item.elementName}
                          key={item.id}
                          onClick={() => selectedElementValue(item)}>
                          {item.elementName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={"select"}>Select Element</MenuItem>
                    )}
                  </Select>
                )}
              />
              <br />
              <br />
              <label className={classes.label}>Level-1 Categories*</label>
              <Controller
                name="broader_categories"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      broaderCategoriesValue
                        ? () => broaderCategoriesValue
                        : () => (
                            <placeholder style={{ color: "#8e8e8e" }}>
                            Select Level-1 Category
                          </placeholder>
                          )
                    }
                    value={broaderCategoriesValue ? broaderCategoriesValue : ""}
                    label="Broader Categories"
                    onChange={onChangeBroaderCategories}>
                    {level1Data &&
                      level1Data.map((item) => (
                        <MenuItem
                          value={item.broaderCategoryName}
                          key={item.id}
                          onClick={() => selectedBroaderCategoriesValue(item)}>
                          {item.broaderCategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <br />
              <br />
              <label className={classes.label}>Level-2 Categories*</label>
              <Controller
                name="sub_categories"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      subCategoriesValue
                        ? () => subCategoriesValue
                        : () => (
                            <placeholder style={{ color: "#8e8e8e" }}>
                            Select Level-2 Category
                          </placeholder>
                          )
                    }
                    value={subCategoriesValue ? subCategoriesValue : ""}
                    label="Sub Categories"
                    onChange={onChangeSubCategories}>
                    {level2Data &&
                      level2Data.map((item) => (
                        <MenuItem
                          value={item.subCategoryName}
                          key={item.id}
                          onClick={() => selectedSubCategoriesValue(item)}>
                          {item.subCategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              // disabled={!isValid || recordLoading}
              className={classes.submitButton}>
              {recordLoading ? "Saving..." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default RelationshipForm;
