import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { getWarningsById } from "../../../store/QualityValidation/qualityValidationSlice";
import { styles } from "../../../styles/Dashboard/data";
import { CircularProgress, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import WarningItem from "../../../common/Helper/warningItem";

const ViewWarnings = (props) => {
  const dispatch = useDispatch();
  const { id, classes } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getWarningsById(id));
          if (res && Boolean(res.dataSourceId)) {
            setData(res);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);
  return (
    <div className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : !data ? (
        <p>No warnings</p>
      ) : (
        <Fragment>
          {data.qualityCheckWarnings &&
            data.qualityCheckWarnings.map((m) => (
              <WarningItem data={m} key={m.code} />
            ))}
        </Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(ViewWarnings);
