import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));
const CreateForm = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h1 className="title">Add new </h1>

      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Username</label>
            <TextField type="text" className="textfield" id="register-email" />
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Email</label>
            <TextField type="text" className="textfield" />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Password</label>
            <TextField type="text" className="textfield" />
          </div>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        className={classes.submitButton}>
        Save Changes
      </Button>
    </div>
  );
};

export default CreateForm;
